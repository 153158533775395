import {ExtractNode} from '../../../../../bindings/ExtractNode';
import {Step} from '../../../../../bindings/Step';
import {StepData} from '../../../../../bindings/StepData';
import {NodeBodyProps, NodeDefinition} from '../../nodes';
import {BoltIcon} from '@heroicons/react/20/solid';
import {GeneralDescriptionNode} from '../GeneralDescriptionNode';

export class ExtractTasksAndDecisionsDefinition implements NodeDefinition {
  public defaultExtractData: ExtractNode = {
    peek: null,
    fastExtract: null,
    query:
      'For the conversation summarize the key points of the conversation in bullet point format, identify all decisions made, and identify any action items. For the summary summarize the content and provide the top 10 key points in bullet point format (- <KEY POINT SUMMARY> \n). This summary should be a summarization and not just a replay of the conversation. The language used should be the same as in the original content (English content should have an English summary, German content German Summary, etc...). For all decisions made provide a quote of the text that represents the decision, summarize the decisions and in a few simple words specific what the decision is. For the action items pull out a list of unique action items. An action item is any followup task or action that was talk about or agreeded upon. Each action item must provide as many details as possible including the date the action item is scheduled for and the names of anyone mentioned in relation to the action. Action items are only tasks that have been clearly stated as an action needed to be done with a specific start or end date. Musings or pondering are not considered action items. Action items must be formatted in a short simple complete sentences. Return an empty list if no action items are required. Using the "Sent On" date to calculate relative dates provide the "when_iso" in ISO 8601 format when possible. When no due date is defined assume that the task must be completed on the day the email was received',
    schema: {
      type: 'object',
      $schema: 'https://json-schema.org/draft/2020-12/schema',
      required: ['summary', 'decisions', 'actionItems'],
      properties: {
        summary: {
          type: 'string',
        },
        decisions: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              quote: {
                type: 'string',
              },
              summary: {
                type: 'string',
              },
              decision: {
                type: 'string',
              },
            },
          },
        },
        actionItems: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              quote: {
                type: 'string',
              },
              when: {
                type: 'string',
              },
              whenIso: {
                type: 'string',
              },
              action: {
                type: 'string',
              },
              people: {
                type: 'string',
              },
            },
          },
        },
      },
    },
    imageExtract: null,
    template: null,
  };
  public defaultData: StepData = {
    nodeType: 'Extract',
    data: this.defaultExtractData,
  };
  public nodeSelectionLabel = 'Extract Tasks';
  public nodeLabel = 'Extract Tasks and Decisions';
  public getNodeIcon(className?: string): JSX.Element {
    return <BoltIcon className={className} />;
  }
  public renderNode(baseProps: NodeBodyProps): JSX.Element {
    return (
      <GeneralDescriptionNode
        {...baseProps}
        description="Extracts out all tasks and decisions stated in the input."
      ></GeneralDescriptionNode>
    );
  }
  public createStep(): Step {
    const newNode: Step = {
      uuid: crypto.randomUUID(),
      label: this.nodeLabel,
      nodeType: 'Extract',
      templateId: 'ExtractTasksAndDecisions',
      data: this.defaultExtractData,
      parentNode: false,
      inputSource: null,
    };
    return newNode;
  }
}
export const EXTRACT_TASKS_NODE_DEFINITION =
  new ExtractTasksAndDecisionsDefinition();
