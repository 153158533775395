import {useContext, useEffect, useRef, useState} from 'react';
import {useLoaderData, useNavigate} from 'react-router-dom';
import {UserContext} from '../../context';
import {CollectionDropUpload} from '../../components/utils/CollectionDropUpload';
import {PlusCircleIcon, TrashIcon} from '@heroicons/react/24/solid';
import {v4 as uuidv4} from 'uuid';
import {
  ChecklistItem as Issue,
  SavedChecklist,
} from '../../api/client/checklist';

interface PageParams {
  collectionUuid: string;
}

interface FileRef {
  name: string;
}

export function NewTimeline() {
  const nav = useNavigate();
  const {client} = useContext(UserContext);
  const {collectionUuid} = useLoaderData() as PageParams;

  const [files, setFiles] = useState<FileRef[]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [collectionName, setCollectionName] = useState<string>('');
  const [checklist, setChecklist] = useState<SavedChecklist | null>(null);

  const caseTitleInput = useRef<HTMLInputElement>(null);

  // Update collection name
  useEffect(() => {
    client?.collection.get(collectionUuid).then(resp => {
      if (resp.result) {
        setCollectionName(resp.result.name);
      }
    });

    client?.collection.listDocuments(collectionUuid).then(resp => {
      if (resp.result) {
        setFiles(resp.result.map(file => ({name: file.displayName})));
      }
    });

    client?.checklist.getByCollection(collectionUuid).then(resp => {
      if (resp) {
        setChecklist(resp);
      }
    });
  }, [collectionUuid, client]);

  const handleSave = () => {
    if (caseTitleInput.current && client) {
      client.collection
        .update(collectionUuid, {
          name: caseTitleInput.current.value,
        })
        .then(() => {
          client.workflow
            .trigger('LegalCaseProcessing', {
              type: 'collection',
              data: {
                collection: collectionUuid,
              },
            })
            .finally(() => {
              nav(`/timeline/${collectionUuid}`);
            });
        });
    }
  };

  return (
    <div className="flex flex-row pt-10 px-8 w-full md:w-[480px] mx-auto">
      <div className="flex flex-col gap-6 w-full">
        <input
          ref={caseTitleInput}
          type="text"
          className="input-lg rounded-lg input-bordered w-128 placeholder:text-gray-600"
          defaultValue={collectionName}
          placeholder="Case Name"
        />

        <hr className="divide-x border-gray-700" />
        {checklist ? <IssueChecklist checklist={checklist} /> : null}

        <hr className="divide-x border-gray-700" />
        <div className="flex flex-col">
          <h2 className="text-lg font-bold pb-2">Case Files</h2>
          <CollectionDropUpload
            collection={collectionUuid}
            onUploadStart={() => {
              setIsUploading(true);
            }}
            onUploadFinished={files => {
              setIsUploading(false);
              setFiles(curFiles =>
                curFiles.concat(files.map(file => ({name: file.name})))
              );
            }}
          >
            <div className="text-sm border-2 border-dotted border-gray-500 py-8 px-4 rounded">
              🪄 Drop files or a folder here to upload.
            </div>
            {isUploading ? (
              <div className="pt-4">
                <div className="text-sm">Uploading {files.length} files...</div>
                <progress className="progress w-full"></progress>
              </div>
            ) : null}
            <FileListing files={files} />
          </CollectionDropUpload>
        </div>
        <hr className="divide-x border-gray-700" />
        <button
          className={`btn btn-primary btn-block ${
            isUploading ? 'btn-disabled' : ''
          }`}
          onClick={handleSave}
        >
          Save
        </button>
      </div>
    </div>
  );
}

function FileListing({files}: {files: FileRef[]}) {
  if (files.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2 pt-4">
      <div className="text-sm">Processed {files.length} files.</div>
      <div className="overflow-x-auto border border-neutral-600 rounded min-h-[128px]">
        <table className="table table-zebra table-xs">
          <thead>
            <tr>
              <th>Filename</th>
            </tr>
          </thead>
          <tbody>
            {files.map(file => (
              <tr key={file.name}>
                <td>{file.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function IssueChecklist({checklist}: {checklist: SavedChecklist}) {
  const [items, setItems] = useState<Issue[]>(checklist.checklist.items);
  const newItemRef = useRef<HTMLInputElement>(null);
  const {client} = useContext(UserContext);

  const handleNewItem = () => {
    if (newItemRef.current) {
      const name = newItemRef.current.value;
      newItemRef.current.value = '';
      if (name && client && checklist) {
        const updated = checklist;
        updated.checklist.items = [
          {
            uuid: uuidv4(),
            title: name,
            description: '',
          },
          ...updated.checklist.items,
        ];
        return client.checklist.update(checklist.uuid, checklist).then(resp => {
          if (resp && resp.result) {
            setItems(resp.result.checklist.items);
          }
        });
      }
    }
  };

  const handleDeleteItem = (item: Issue) => {
    // Delete from server, update the list
    if (client && checklist) {
      const updated = checklist;
      updated.checklist.items = updated.checklist.items.flatMap(value =>
        value.title === item.title ? [] : [value]
      );

      client.checklist.update(checklist.uuid, updated).then(resp => {
        if (resp && resp.result) {
          setItems(resp.result.checklist.items);
        }
      });
    }
  };

  return (
    <div className="flex flex-col">
      <h2 className="text-lg font-bold">Case Roadmap</h2>
      <div className="text-xs">
        During analysis, each document will be checked against the roadmap items
        for relevance and tagged.
      </div>
      <ul className="list-none pt-4 flex flex-col gap-2">
        <li>
          <div className="join w-full">
            <input
              ref={newItemRef}
              type="text"
              className="w-full input rounded input-bordered placeholder:text-gray-600 join-item"
              placeholder="Breach of contract"
            />
            <button
              className="btn join-item rounded-r btn-accent"
              onClick={handleNewItem}
            >
              <PlusCircleIcon className="w-4" />
              Add
            </button>
          </div>
        </li>
        {items.map((item, idx) => (
          <ChecklistItem
            key={idx}
            item={item}
            onDelete={() => handleDeleteItem(item)}
          />
        ))}
      </ul>
    </div>
  );
}

interface ChecklistItemProps {
  item: Issue;
  onDelete: () => void;
}

function ChecklistItem({item, onDelete}: ChecklistItemProps) {
  return (
    <li className="list-item border border-gray-600 px-4 py-2">
      <div className="flex flex-row items-center">
        <div>{item.title}</div>
        <button
          className="btn btn-circle hover:btn-error btn-sm ml-auto"
          onClick={onDelete}
        >
          <TrashIcon className="w-4" />
        </button>
      </div>
    </li>
  );
}
