import {
  ArrowPathIcon,
  ExclamationTriangleIcon,
  ListBulletIcon,
  PencilIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';
import {
  ChangeEvent,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {v4 as uuidv4} from 'uuid';
import {UserContext} from '../../context';
import {
  ChecklistItem as ChecklistItemInterface,
  SavedChecklist,
} from '../../api/client/checklist';
import {ModalType} from '../../components/modals';
import {ApiResponse} from '../../../bindings/api/ApiResponse';

const LOCAL_UUID_PREFIX = 'EMPTY_LIST';
const EMPTY_CHECKLIST = {
  uuid: LOCAL_UUID_PREFIX,
  version: 1,
  checklist: {
    name: 'My Talking Points',
    items: [],
  },
};

export interface GoalItem extends ChecklistItemInterface {
  isComplete: boolean;
}

export interface CheckListProps {
  isAnalyzing: boolean;
  checklist: GoalItem[];
  setCheckList: React.Dispatch<React.SetStateAction<GoalItem[]>>;
}

const TEST_GOALS = [
  {
    uuid: uuidv4(),
    title: 'Greet the audience',
    description: 'Say hello and mention your name.',
    isComplete: false,
  },
  {
    uuid: uuidv4(),
    title: 'Testing the Tool',
    description: 'Talk about how you are testing this tool',
    isComplete: false,
  },
];

const DEMO_GOALS = [
  {
    uuid: uuidv4(),
    title: 'Greet the audience',
    description: 'Say hello and dive into the demo!',
    isComplete: false,
  },
  {
    uuid: uuidv4(),
    title: 'Talk about the problem',
    description:
      'Following a script can be hard! Sometimes we lose our place, sometimes we forget.',
    isComplete: false,
  },
  {
    uuid: uuidv4(),
    title: 'Talk about the solution',
    description:
      "Talk about how the tool solves the problem. It listens to your conversations and makes sure you're hitting your talking points.",
    isComplete: false,
  },
  {
    uuid: uuidv4(),
    title: 'Technology Stack',
    description:
      "Powered by AI models that understands what you're talking about versus looking at keywords.",
    isComplete: false,
  },
  {
    uuid: uuidv4(),
    title: 'Call to action',
    description:
      'Love to get feedback from whoever watches this demo, please get in touch :)',
    isComplete: false,
  },
];

const SAMPLE_CHECKLIST = [
  {
    uuid: LOCAL_UUID_PREFIX + uuidv4(),
    version: 1,
    checklist: {name: 'Quick Example', items: TEST_GOALS},
  },
  {
    uuid: LOCAL_UUID_PREFIX + uuidv4(),
    version: 1,
    checklist: {name: 'Demo', items: DEMO_GOALS},
  },
];

export function CheckList({
  isAnalyzing,
  checklist,
  setCheckList,
}: CheckListProps) {
  const {client} = useContext(UserContext);
  const [savedChecklists, setSavedChecklists] = useState<SavedChecklist[]>([]);
  const [selectedChecklist, setSelectedChecklist] = useState<
    SavedChecklist | undefined
  >();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const addEditGoalModal = useRef<ModalType>(null);

  const setDefaultChecklist = useCallback(() => {
    setSelectedChecklist(SAMPLE_CHECKLIST[0]);
    setCheckList(SAMPLE_CHECKLIST[0].checklist.items);
  }, [setCheckList]);

  useEffect(() => {
    setIsLoading(true);
    client?.checklist
      .list()
      .then(checklists => {
        if (checklists && checklists.result && checklists.result.length > 0) {
          const list = checklists.result;
          setSavedChecklists(list);

          if (list.length > 0) {
            setSelectedChecklist(list[0]);
            setCheckList(list[0].checklist.items as GoalItem[]);
          }
        } else {
          setDefaultChecklist();
        }
      })
      .catch(err => {
        console.error('Error loading initial checklist ', err);
        setDefaultChecklist();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [client, setDefaultChecklist, setCheckList]);

  const saveChecklist = async (
    checklist: SavedChecklist
  ): Promise<ApiResponse<SavedChecklist> | null> => {
    if (client) {
      if (checklist.uuid.startsWith(LOCAL_UUID_PREFIX)) {
        return client.checklist.save(checklist);
      } else {
        return client.checklist.update(checklist.uuid, checklist);
      }
    }
    return null;
  };

  const addItem = (title: string, description: string) => {
    if (selectedChecklist) {
      setCheckList(goals => {
        const update = [...goals];
        update.push({
          uuid: uuidv4(),
          title,
          description,
          isComplete: false,
        });

        const currentUuid = selectedChecklist.uuid;
        selectedChecklist.checklist.items = update;
        saveChecklistAndUpdate(currentUuid, selectedChecklist);
        return update;
      });
    }
  };

  const manualToggle = (uuid: string) => {
    const goal = checklist.find(item => item.uuid === uuid);
    if (goal) {
      goal.isComplete = !goal.isComplete;
      setCheckList(goals => {
        return goals.map(item => {
          return item.uuid === goal.uuid ? goal : item;
        });
      });
    }
  };

  const saveChecklistAndUpdate = (
    currentUuid: string,
    checklist: SavedChecklist
  ) => {
    saveChecklist(checklist)
      .then(response => {
        if (response && response.result) {
          const updatedChecklist = response.result;
          setSavedChecklists(list => {
            const idx = list.findIndex(item => item.uuid === currentUuid);
            list[idx] = updatedChecklist;
            setSelectedChecklist(updatedChecklist);
            return list;
          });
        }
      })
      .catch(err => {
        console.error('Error saving checklist ', err);
      });
  };

  const deleteItem = (uuid: string) => {
    if (selectedChecklist) {
      setCheckList(goals => {
        const update = goals.flatMap(item => {
          return item.uuid === uuid ? [] : [item];
        });

        const currentUuid = selectedChecklist.uuid;
        selectedChecklist.checklist.items = update;
        saveChecklist(selectedChecklist).then(response => {
          if (response && response.result) {
            const updatedChecklist = response.result;
            setSavedChecklists(list => {
              const idx = list.findIndex(item => item.uuid === currentUuid);
              list[idx] = updatedChecklist;
              return list;
            });
          }
        });

        return update;
      });
    }
  };

  const saveUpdatedItem = (updatedGoal: GoalItem) => {
    if (selectedChecklist) {
      setCheckList(goals => {
        const update = goals.flatMap(item => {
          return item.uuid === updatedGoal.uuid ? [updatedGoal] : [item];
        });

        const currentUuid = selectedChecklist.uuid;
        selectedChecklist.checklist.items = update;
        saveChecklist(selectedChecklist).then(response => {
          if (response && response.result) {
            const updatedChecklist = response.result;
            setSavedChecklists(list => {
              const idx = list.findIndex(item => item.uuid === currentUuid);
              list[idx] = updatedChecklist;
              return list;
            });
          }
        });

        return update;
      });
    }
  };

  const onAddChecklist = () => {
    setSavedChecklists(list => {
      const newList: SavedChecklist = {...EMPTY_CHECKLIST};
      newList.uuid += uuidv4();
      list.push(newList);
      setSelectedChecklist(newList);
      setCheckList(newList.checklist.items as GoalItem[]);
      return list;
    });
  };

  const checklistNameInput = useRef(null);
  const onEditChecklist = () => {
    setIsEditing(state => {
      if (state && selectedChecklist && checklistNameInput.current) {
        selectedChecklist.checklist.name = (
          checklistNameInput.current as HTMLInputElement
        ).value;
        // Update and save to server
        setSavedChecklists(list => {
          const idx = list.findIndex(
            item => item.uuid === selectedChecklist.uuid
          );
          list[idx] = selectedChecklist;

          saveChecklistAndUpdate(selectedChecklist.uuid, selectedChecklist);
          return list;
        });
      }
      return !state;
    });
  };

  const onDeleteChecklist = () => {
    setSavedChecklists(list => {
      if (selectedChecklist) {
        const selectedUuid = selectedChecklist.uuid;
        const idx = list.findIndex(item => item.uuid === selectedUuid);
        list.splice(idx, 1);

        if (client && !selectedUuid.startsWith(LOCAL_UUID_PREFIX)) {
          client.checklist.delete(selectedUuid).catch(err => {
            console.error('Error deleting checklist ', err);
          });
        }
      }

      if (list.length > 0) {
        setSelectedChecklist(list[0]);
        setCheckList(list[0].checklist.items as GoalItem[]);
      } else {
        setSelectedChecklist(undefined);
        setCheckList([]);
      }
      return list;
    });
  };

  const onSelectChecklist = (e: ChangeEvent<HTMLSelectElement>) => {
    const listId = e.target.value;
    const list = savedChecklists.find(list => list.uuid === listId);
    if (list) {
      setSelectedChecklist(list);
      setCheckList(list.checklist.items as GoalItem[]);
    }
  };

  return (
    <div className="flex flex-col gap-2 w-full">
      <h2 className="font-bold flex flex-row gap-2 items-center justify-between">
        <div className="text-2xl flex flex-row gap-2">
          {isAnalyzing ? (
            <ArrowPathIcon className="w-6 animate-spin" />
          ) : (
            <ListBulletIcon className="w-6" />
          )}
          <span>Checklist</span>
        </div>
        <button className="btn btn-neutral btn-sm" onClick={onAddChecklist}>
          <PlusCircleIcon className="w-4" />
          New List
        </button>
      </h2>
      <div className="text-gray-500 text-sm">
        As you talk, any talking points listed below will be automated detected
        and marked completed. The list is analyzed every{' '}
        <strong className="text-accent">30 seconds</strong>.
      </div>
      <div className="flex flex-row gap-2 w-full">
        {isEditing ? (
          <input
            ref={checklistNameInput}
            type="text"
            defaultValue={selectedChecklist?.checklist.name}
            className="form-text input input-bordered w-full bg-neutral"
          />
        ) : (
          <label className="form-control w-full">
            <select
              className="select select-bordered text-xl font-medium overflow-clip w-full"
              onChange={onSelectChecklist}
              value={selectedChecklist?.uuid}
            >
              {savedChecklists.map(list => (
                <option key={list.uuid} value={list.uuid}>
                  {list.checklist.name}
                </option>
              ))}
            </select>
          </label>
        )}
        <button
          className={`btn ${isEditing ? 'btn-success' : 'btn-neutral'}`}
          onClick={onEditChecklist}
        >
          {isEditing ? 'Save' : 'edit'}
        </button>
        <button
          className="btn btn-error btn-square"
          onClick={onDeleteChecklist}
        >
          <TrashIcon className="w-5" />
        </button>
      </div>
      {isLoading ? (
        <div className="flex flex-col items-center gap-4 py-4">
          <ArrowPathIcon className="w-8 animate-spin" />
          <div className="text-xl font-bold">Loading checklists...</div>
        </div>
      ) : (
        <>
          <ChecklistItems
            checklist={checklist}
            onManualToggle={manualToggle}
            onDeleteItem={deleteItem}
            onUpdateItem={saveUpdatedItem}
          />
          <button
            className="btn btn-block flex flex-row"
            onClick={() => addEditGoalModal.current?.showModal()}
          >
            <PlusCircleIcon className="w-6" />
            Add Goal
          </button>
        </>
      )}
      <AddEditGoalModal modalRef={addEditGoalModal} onComplete={addItem} />
    </div>
  );
}

interface ChecklistItemsProps {
  checklist: GoalItem[];
  onDeleteItem: (uuid: string) => void;
  onManualToggle: (uuid: string) => void;
  onUpdateItem: (updated: GoalItem) => void;
}

function ChecklistItems({
  checklist,
  onDeleteItem,
  onManualToggle,
  onUpdateItem,
}: ChecklistItemsProps) {
  const [editGoal, setEditGoal] = useState<GoalItem | null>(null);
  const editModalRef = useRef<ModalType>(null);

  const handleEdit = (goal: GoalItem) => {
    setEditGoal(goal);
    editModalRef.current?.showModal();
  };

  const handleSaveEdit = (updatedTitle: string, updatedDesc: string) => {
    if (editGoal) {
      const updated: GoalItem = {
        uuid: editGoal.uuid,
        isComplete: editGoal.isComplete,
        title: updatedTitle,
        description: updatedDesc,
      };
      onUpdateItem(updated);
    }
    setEditGoal(null);
  };

  if (checklist.length === 0) {
    return (
      <div className="flex flex-col items-center py-4">
        <div className="font-semibold text-warning flex flex-row items-center gap-2">
          <ExclamationTriangleIcon className="w-6" />
          No goals added yet!
        </div>
        <div className="font-semibold">
          Add a goal or choose a template to get started.
        </div>
      </div>
    );
  }

  return (
    <>
      <ul className="list-none">
        {checklist.map(goal => (
          <ChecklistItem
            key={goal.uuid}
            goal={goal}
            onEdit={() => handleEdit(goal)}
            onDelete={() => onDeleteItem(goal.uuid)}
            onToggle={() => onManualToggle(goal.uuid)}
          />
        ))}
      </ul>
      <AddEditGoalModal
        modalRef={editModalRef}
        existingGoal={editGoal ? editGoal : undefined}
        onComplete={handleSaveEdit}
        onCancel={() => editModalRef.current?.close()}
      />
    </>
  );
}

interface ChecklistItemProps {
  goal: GoalItem;
  onEdit: () => void;
  onDelete: () => void;
  onToggle: () => void;
}

function ChecklistItem({goal, onEdit, onDelete, onToggle}: ChecklistItemProps) {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  useEffect(() => {
    setIsCollapsed(goal.isComplete);
  }, [goal.isComplete]);

  return (
    <li key={goal.uuid} className="form-control list-item">
      <div className="label cursor-pointer flex flex-row justify-normal gap-4 items-start">
        <input
          type="checkbox"
          className={`checkbox checkbox-lg mt-2 ${
            goal.isComplete ? 'checkbox-success' : ''
          }`}
          checked={goal.isComplete}
          onChange={onToggle}
          readOnly
        />
        <div
          className={`label-text collapse ${
            isCollapsed ? '' : 'collapse-open'
          }`}
          onClick={() => {
            setIsCollapsed(cur => !cur);
          }}
        >
          <div
            className={`collapse-title min-h-0 text-lg font-semibold pt-2 pb-2 ${
              goal.isComplete ? 'text-gray-500 line-through' : 'text-white'
            }`}
          >
            {goal.title}
          </div>
          <div
            className={`collapse-content pt-0 ${
              goal.isComplete ? ' text-gray-500' : 'text-gray-400'
            }`}
          >
            <pre className="prose font-sans whitespace-break-spaces select-text">
              {goal.description}
            </pre>
          </div>
        </div>
        <div
          className={`ml-auto flex flex-col gap-2 mt-2 ${
            isCollapsed ? 'hidden' : ''
          }`}
        >
          <button
            className="btn btn-circle hover:btn-success btn-sm"
            onClick={onEdit}
          >
            <PencilIcon className="w-4" />
          </button>

          <button
            className="btn btn-circle hover:btn-error btn-sm"
            onClick={onDelete}
          >
            <TrashIcon className="w-4" />
          </button>
        </div>
      </div>
    </li>
  );
}

export interface AddEditGoalModalProps {
  modalRef: RefObject<ModalType>;
  existingGoal?: GoalItem;
  onComplete: (title: string, description: string) => void;
  onCancel?: () => void;
}

function AddEditGoalModal({
  modalRef,
  existingGoal,
  onComplete,
  onCancel = () => {},
}: AddEditGoalModalProps) {
  const titleInput = useRef<HTMLInputElement>(null);
  const descInput = useRef<HTMLTextAreaElement>(null);

  const handleAddEdit = () => {
    if (titleInput.current && descInput.current) {
      onComplete(titleInput.current.value, descInput.current.value);
      titleInput.current.value = '';
      descInput.current.value = '';
    }

    modalRef.current?.close();
  };

  return (
    <dialog ref={modalRef as RefObject<HTMLDialogElement>} className="modal">
      <div className="modal-box">
        <h1 className="text-2xl font-medium flex flex-row items-center gap-2">
          {existingGoal ? 'Edit Goal' : 'Add a new goal'}
        </h1>
        <div className="flex flex-col gap-4">
          <label className="form-control">
            <div className="label">
              <span className="label-text font-bold">Title</span>
            </div>
            <input
              ref={titleInput}
              type="text"
              className="input input-bordered w-full"
              placeholder="Ex: Greet the audience"
              defaultValue={existingGoal?.title}
            />
          </label>
          <label className="form-control">
            <div className="label">
              <span className="label-text font-bold">Short Description</span>
            </div>
            <textarea
              ref={descInput}
              className="textarea textarea-bordered"
              placeholder="Ex: Say hello and introduce myself"
              defaultValue={existingGoal?.description}
            />
          </label>
          <div className="flex flex-row place-content-end gap-4">
            <button
              type="button"
              className="btn btn-success"
              onClick={() => handleAddEdit()}
            >
              Save
            </button>
            <form method="dialog">
              <button
                type="button"
                className="btn btn-error"
                onClick={() => {
                  modalRef.current?.close();
                  onCancel();
                }}
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      </div>
    </dialog>
  );
}
