import {RefObject, useCallback, useContext, useRef, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {UserContext} from '../../context';
import {Id, toast} from 'react-toastify';
import {ModalType} from '../modals';
import {CloudArrowUpIcon} from '@heroicons/react/20/solid';
import {AxiosProgressEvent} from 'axios';
import {MAX_FILE_SIZE, SightglassClient} from '../../api/Client';
import {LensType} from '../../../bindings/api/LensType';

type UploadUpdateCallback = (filename: string, progress: number) => void;

interface CollectionDropUploadProps {
  // Leave out to create a new collection for each drop.
  collection?: string;
  onUploadFinished: (files: File[], docUuids: string[]) => void;
  // When an upload first starts
  onUploadStart?: (files: File[]) => void;
  onUploadUpdate?: UploadUpdateCallback;
  onCollectionCreated?: (collection_uuid: string) => void;
  children: React.ReactNode;
  mockUpload?: boolean;
  defaultCollectionType?: LensType;
}

export async function handleCollectionUpload(
  client: SightglassClient,
  collectionUuid: string,
  files: File[],
  onUploadUpdate: UploadUpdateCallback = () => {}
) {
  // const uploads = [];

  const filteredFiles = files.filter(file => {
    console.debug('file size ', file.size);
    if (file.size > MAX_FILE_SIZE) {
      toast.error(
        `File too large to upload, max 500 MB. Skipping ${file.name}`
      );
      return false;
    }
    return true;
  });

  if (filteredFiles.length > 0) {
    const names = filteredFiles.map(file => file.name);
    return client.collection
      .presignUpload(collectionUuid, names)
      .then(result => {
        const uploads = [];
        const progress: {[key: string]: number} = {};
        filteredFiles.forEach(file => {
          progress[file.name] = 0;
        });
        if (result && result.result) {
          // Set initial upload state
          const length = filteredFiles.length;
          let toastRef: Id;
          if (length > 1) {
            const rest = length - 1;
            toastRef = toast(
              `Uploading ${filteredFiles[0].name} and ${rest} more...`,
              {
                progress: 0,
                autoClose: false,
              }
            );
          } else {
            toastRef = toast(`Uploading ${filteredFiles[0].name}...`, {
              progress: 0,
              autoClose: false,
            });
          }

          for (let i = 0; i < length; i++) {
            const file = filteredFiles[i];
            const presign = result.result[i];
            uploads.push(
              client?.collection
                .uploadPresignedDocument(
                  presign.uploadUrl,
                  file,
                  (p: AxiosProgressEvent) => {
                    progress[file.name] = p.progress ?? 0;
                    updateProgress(toastRef, progress);
                    onUploadUpdate(file.name, p.progress ?? 0);
                  }
                )
                .then(() => {
                  return client?.collection
                    .finishUpload(collectionUuid, presign.uuid)
                    .then(response => {
                      delete progress[file.name];
                      updateProgress(toastRef, progress);
                      return response.result?.uuid ?? '';
                    })
                    .catch(err => {
                      delete progress[file.name];
                      updateProgress(toastRef, progress);
                      toast.error(`Network Error uploading ${file.name}!`);
                      throw err;
                    });
                })
                .catch(err => {
                  delete progress[file.name];
                  updateProgress(toastRef, progress);
                  toast.error(`Network Error uploading ${file.name}!`);
                  throw err;
                })
            );
          }
        }
        return Promise.all(uploads);
      });
  }
}

export function CollectionDropUpload({
  collection,
  onUploadFinished,
  onUploadStart = () => {},
  onCollectionCreated = (_collectionUuid: string) => {},
  onUploadUpdate,
  children,
  mockUpload = false,
  defaultCollectionType = 'General',
}: CollectionDropUploadProps) {
  const {client} = useContext(UserContext);
  const [isDragging, setIsDragging] = useState(false);
  const dropIndicatorModal = useRef<ModalType>();
  const handleOnDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setIsDragging(false);
      if (acceptedFiles.length === 0) {
        // Nothing to do here;
        return;
      }

      // Create a new collection.
      let collection_uuid;
      if (!collection && !mockUpload) {
        const response = await client?.collection
          .create('New Collection', defaultCollectionType)
          .catch(err => {
            console.error(err);
            toast('Unable to upload files', {theme: 'colored', type: 'error'});
          });

        if (response && response.result) {
          collection_uuid = response.result.uuid;
          onCollectionCreated(collection_uuid);
        }
      } else {
        collection_uuid = collection;
      }

      if (!collection_uuid) {
        if (mockUpload) {
          onUploadStart(acceptedFiles);
          await new Promise(resolve => {
            setTimeout(() => {
              onUploadFinished(acceptedFiles, []);
              resolve(null);
            }, 10000);
          });
        } else {
          console.error('No collection_uuid set.');
          toast('Unable to upload files', {theme: 'colored', type: 'error'});
        }
        return;
      }

      if (client) {
        onUploadStart(acceptedFiles);
        await handleCollectionUpload(
          client,
          collection_uuid,
          acceptedFiles,
          onUploadUpdate
        )
          .then(uploadedDocs =>
            onUploadFinished(acceptedFiles, uploadedDocs ?? [])
          )
          .catch(() => {});
      }
    },
    [client, collection, onUploadFinished, onUploadStart, onUploadUpdate]
  );

  const {getRootProps, getInputProps} = useDropzone({
    onDrop: handleOnDrop,
    noClick: true,
    onDragEnter: () => setIsDragging(true),
    onDragLeave: () => setIsDragging(false),
    onDropRejected: () => setIsDragging(false),
  });
  return (
    <div {...getRootProps()} className="w-full">
      <input {...getInputProps()} />
      {children}
      <dialog
        ref={dropIndicatorModal as RefObject<HTMLDialogElement>}
        className={`bg-transparent outline-none modal ${
          isDragging ? 'modal-open' : ''
        }`}
      >
        <div className="modal-box border border-accent w-fit flex flex-col items-center gap-2">
          <CloudArrowUpIcon className="w-28" />
          <h3 className="font-bold text-lg">Drop files to upload</h3>
        </div>
      </dialog>
    </div>
  );
}

function updateProgress(toastRef: Id, progress: {[key: string]: number}) {
  const length = Object.keys(progress).length;
  if (length === 0) {
    toast.done(toastRef);
    toast('Finished uploading!', {
      theme: 'colored',
      type: 'success',
    });
  } else {
    const total = Object.values(progress).reduce((previous, current) => {
      return previous + current;
    }, 0);

    const progressValue = total / length;
    toast.update(toastRef, {progress: progressValue});
  }
}
