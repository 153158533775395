import {useAuth0} from '@auth0/auth0-react';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {PencilIcon, TrashIcon} from '@heroicons/react/20/solid';
import {DateTime} from 'luxon';
import CopyTxtToClipboardButton from '../components/buttons/CopyTxtToClipboardButton';
import {UserContext} from '../context';
import {ApiKey} from '../../bindings/api/ApiKey';

function ApiKeys() {
  const {client} = useContext(UserContext);
  const {isLoading} = useAuth0();
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);
  const [apiKey, setApiKey] = useState<string | undefined>(undefined);
  const [deleting, setDeleting] = useState<boolean>(false);

  const newKeyModalRef = useRef<HTMLDialogElement>(null);
  const keyModalRef = useRef<HTMLDialogElement>(null);
  const newKeyNameRef = useRef<HTMLInputElement>(null);

  const refreshKeys = useCallback(() => {
    if (!isLoading && client) {
      client.auth.getApiKeys().then(resp => setApiKeys(resp.keys));
    }
  }, [isLoading, client]);

  useEffect(() => {
    refreshKeys();
  }, [refreshKeys]);

  const deleteKey = (key: ApiKey) => {
    setDeleting(true);
    if (!isLoading && client) {
      client.auth
        .deleteApiKey(key.uuid)
        .then(() => refreshKeys())
        .finally(() => setDeleting(false));
    }
  };

  const createNewKey = (key: ApiKey | undefined) => {
    newKeyModalRef.current?.showModal();

    if (newKeyNameRef.current) {
      newKeyNameRef.current.value = key ? key.name : '';
    }

    if (newKeyModalRef.current) {
      newKeyModalRef.current.onclose = () => {
        if (
          newKeyModalRef.current?.returnValue === 'Ok' &&
          newKeyNameRef.current?.value
        ) {
          const name = newKeyNameRef.current.value;
          newKeyNameRef.current.value = '';

          const promise = key
            ? client?.auth.updateApiKey(key.uuid, name)
            : client?.auth.createApiKey(name).then(newKey => {
                if (newKey) {
                  setApiKey(newKey.key);
                  keyModalRef.current?.showModal();
                  if (keyModalRef.current) {
                    keyModalRef.current.onclose = () => {
                      setApiKey(undefined);
                    };
                  }
                }
              });

          return promise?.finally(() => refreshKeys());
        }
      };
    }
  };

  return (
    <div className="bg-base-100 p-4 mx-auto">
      <h1 className="text-5xl font-bold">API Keys</h1>
      <p className="py-4">
        Your secret API keys are listed in the table below. These keys are
        directly linked to your account. Please do not share or expose your API
        keys to unauthorized parties.
      </p>
      <p className="py-2">
        {' '}
        For security your API key will only be fully shown when it is created.
      </p>
      <div className="overflow-x-auto py-4">
        <table className="table">
          {/* head */}
          <thead>
            <tr>
              <th>Name</th>
              <th>Key</th>
              <th>Created</th>
              <th>Expires</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {apiKeys.map(key => (
              <tr key={key.name}>
                <td>{key.name}</td>
                <td>{key.key}</td>
                <td>
                  {DateTime.fromISO(key.createdAt)
                    .toLocaleString(DateTime.DATE_MED)
                    .toString()}
                </td>

                <td>
                  {' '}
                  {DateTime.fromISO(key.expiresOn)
                    .toLocaleString(DateTime.DATE_MED)
                    .toString()}
                </td>
                <th>
                  <button className="btn btn-outline btn-xs mr-2">
                    <PencilIcon
                      className="w-4 h-4"
                      onClick={() => createNewKey(key)}
                    ></PencilIcon>
                  </button>

                  <button
                    className="btn btn-error btn-outline btn-xs"
                    onClick={() => deleteKey(key)}
                    disabled={deleting}
                  >
                    {deleting ? (
                      <span className="loading loading-spinner w-4 h-4"></span>
                    ) : (
                      <TrashIcon className="w-4 h-4"></TrashIcon>
                    )}
                  </button>
                </th>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button
        className="btn btn-primary"
        onClick={() => createNewKey(undefined)}
      >
        Add New API Key
      </button>
      <dialog
        id="new_api_key_modal"
        className="modal modal-bottom sm:modal-middle"
        ref={newKeyModalRef}
      >
        <div className="modal-box">
          <h3 className="font-bold text-lg">Key Name</h3>
          <p className="py-4">Please enter a name for the API key</p>
          <div className="modal-action justify-start">
            <form method="dialog" className="w-full">
              <input
                type="text"
                placeholder="Type here"
                className="input input-bordered w-full"
                ref={newKeyNameRef}
              />
              <div className="flex pt-2 justify-end">
                <button
                  className="btn mr-1 btn-primary"
                  onClick={() => newKeyModalRef.current?.close('Ok')}
                >
                  Ok
                </button>
                <button
                  className="btn"
                  onClick={() => newKeyModalRef.current?.close('Close')}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </dialog>
      <dialog
        id="key_modal"
        className="modal modal-bottom sm:modal-middle"
        ref={keyModalRef}
      >
        <div className="modal-box">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
              ✕
            </button>
          </form>
          <h3 className="font-bold text-lg">API Key</h3>
          <p className="py-4">
            This will be the only time we will display the full API key. Please
            copy the key and save it in a safe location. If you lose this key
            you will need to generate a new one
          </p>

          {apiKey ? (
            <CopyTxtToClipboardButton text={apiKey}></CopyTxtToClipboardButton>
          ) : null}
        </div>
      </dialog>
    </div>
  );
}

export default ApiKeys;
