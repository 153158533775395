import {Content, EditorContent} from '@tiptap/react';
import {useEffect, useRef, useState} from 'react';

import {useBlockEditor} from '../../hooks/useBlockEditor';

import '../../styles/index.css';

import {EditorHeader} from './components/EditorHeader';
import {DetailedDocumentResponse} from '../../../../../bindings/api/DetailedDocumentResponse';
import {ParsedDocContent, ProcessedAudio} from '../../../../api/Client';

import {EditorSidebar} from './components/EditorSidebar';

export interface BlockEditorProps {
  document?: DetailedDocumentResponse;
  editorOnly?: boolean;
  collectionUuid: string;
  documentUuid: string;
}

export interface EditorControlApi {
  navToText: (text: string) => void;
}

// Block editor based off tiptap block editor example
// Lots of editing features removed to make it a simple view only app
// The full example with all the cool features can be found here
// https://github.com/ueberdosis/tiptap-templates/tree/main/templates/next-block-editor-app
export const BlockEditor = ({
  document,
  documentUuid,
  collectionUuid,
  editorOnly = false,
}: BlockEditorProps) => {
  const menuContainerRef = useRef(null);

  const {editor, characterCount} = useBlockEditor(false);
  const [sidebarVisible, setSidebarVisible] = useState<boolean>(true);

  useEffect(() => {
    if (document && document.content) {
      const docContent = document.content as any as ParsedDocContent;
      const contentType = document.contentType;

      let content: Content = `<p>${docContent.content}</p>`;
      if (contentType) {
        if (contentType === 'application/json') {
          content = `<pre><code class="language-json">${docContent.content}</code><pre>`;
        } else if (
          contentType === 'application/pdf' ||
          contentType.indexOf('image') !== -1
        ) {
          let split = '\n\n';
          if (docContent.content.includes('\r')) {
            split = '\r\n\r\n';
          }
          const processed = docContent.content
            .split(split)
            .filter(txt => {
              return txt.length > 0;
            })
            .map(txt => {
              return {
                type: 'paragraph',
                content: txt
                  .split('\n')
                  .filter(txt => {
                    return txt.length > 0 && txt !== '\r';
                  })
                  .flatMap((line, index) => {
                    if (index !== 0) {
                      return [
                        {
                          type: 'hardBreak',
                        },
                        {
                          type: 'text',
                          text: line.replaceAll('\r', ''),
                        },
                      ];
                    } else {
                      return {
                        type: 'text',
                        text: line.replaceAll('\r', ''),
                      };
                    }
                  }),
              };
            });
          content = {
            type: 'doc',
            content: processed,
          };
        } else if (
          contentType === 'text/html' ||
          contentType === 'text/plain'
        ) {
          content = {
            type: 'doc',
            content: [{type: 'text', text: docContent.content}],
          };
        } else if (contentType.indexOf('audio') !== -1) {
          const audioJson: ProcessedAudio = JSON.parse(docContent.content);
          if (audioJson.utterances) {
            const docContent = audioJson.utterances.map(utterance => {
              return {
                type: 'paragraph',
                content: [
                  {
                    type: 'text',
                    marks: [
                      {
                        type: 'bold',
                      },
                    ],
                    text: `Speaker ${utterance.speaker}: `,
                  },
                  {
                    type: 'text',
                    text: utterance.text,
                  },
                ],
              };
            });

            content = {
              type: 'doc',
              content: docContent,
            };
          } else if (audioJson.text && audioJson.text.length > 0) {
            content = {
              type: 'doc',
              content: [
                {
                  type: 'paragraph',
                  content: [
                    {
                      type: 'text',
                      text: audioJson.text,
                    },
                  ],
                },
              ],
            };
          }
        }
      }

      editor?.commands.setContent(content);
    }
  }, [document, editor]);

  if (!editor) {
    return null;
  }

  if (editorOnly) {
    return (
      <EditorContent
        editor={editor}
        className="flex-1 overflow-y-auto bg-black rounded p-4"
      />
    );
  } else {
    return (
      <div className={`drawer ${sidebarVisible ? 'drawer-open' : null}`}>
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content flex flex-col items-center justify-center max-w-[960px] mx-auto">
          <div className="flex h-full max-w-[960px]" ref={menuContainerRef}>
            <div className="relative flex flex-col flex-1 h-full overflow-hidden">
              <EditorHeader
                characters={characterCount.characters()}
                words={characterCount.words()}
                name={document?.displayName || ''}
                sidebarOnChange={visible => setSidebarVisible(visible)}
              />
              <EditorContent
                editor={editor}
                className="flex-1 overflow-y-auto"
              />
            </div>
          </div>
        </div>
        <div className="drawer-side w-[400px]">
          <label
            htmlFor="my-drawer-2"
            aria-label="close sidebar"
            className="drawer-overlay"
          ></label>
          <EditorSidebar
            editor={editor}
            collectionUuid={collectionUuid}
            documentUuid={documentUuid}
          ></EditorSidebar>
        </div>
      </div>
    );
  }
};

export default BlockEditor;
