import {createContext} from 'react';
import {SightglassClient, UserWorkflow} from './api/Client';
import {CurrentUser} from '../../cloudflow/bindings/api/CurrentUser';

export interface UserContext {
  profile?: CurrentUser;
  authToken?: string;
  client?: SightglassClient;
  setProfile: (profile: CurrentUser) => void;
}

export interface WorkflowCallback {
  onComplete: (uuid: string) => void;
}

export const UserContext = createContext<UserContext>({
  setProfile: () => {},
});

export interface WatcherContext {
  watchWorkflow: (
    name: string,
    workflow_instance: string,
    callback?: WorkflowCallback
  ) => void;
  setClient: (client: SightglassClient) => void;
}

export const WatcherContext = createContext<WatcherContext>({
  setClient: () => {},
  watchWorkflow: () => {},
});

export interface WorkflowContext {
  workflowDef?: UserWorkflow;
}

export const WorkflowContext = createContext<WorkflowContext>({});
