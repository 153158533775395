import {ReactNode, useCallback, useContext, useEffect, useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  MinusIcon,
  PlusIcon,
} from '@heroicons/react/20/solid';
import {DocumentResponse} from '../../../bindings/api/DocumentResponse';
import type {PDFDocumentProxy} from 'pdfjs-dist';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import {UserContext} from '../../context';
import {DetailedDocumentResponse} from '../../../bindings/api/DetailedDocumentResponse';
import {BlockEditor} from '../../components/editor/components/BlockEditor';
import {TAG_SECTIONS, TagListSection} from './TimelineTagBar';

const DEFAULT_DOC_OPTIONS = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

pdfjs.GlobalWorkerOptions.workerSrc = '/js/pdf.worker.min.mjs';

interface DocumentDetailsProps {
  selectedDocument: DocumentResponse | null;
  collectionUuid: string;
  // sizeTarget: MutableRefObject<HTMLDivElement | null>;
  selectedCitation: string | null;
}

export function DocumentDetails({
  selectedDocument,
  selectedCitation,
  collectionUuid,
}: DocumentDetailsProps) {
  const [tags, setTags] = useState<{[key: string]: any[]}>({});

  useEffect(() => {
    if (!selectedDocument) {
      return;
    }

    const tagMap: {[key: string]: any[]} = {};
    selectedDocument.tags.forEach(tag => {
      const key = tag.key;
      const value = JSON.parse(tag.value);
      if (tagMap[key]) {
        tagMap[key].push(value);
      } else {
        tagMap[key] = [value];
      }
    });
    setTags(tagMap);
  }, [selectedDocument]);

  if (!selectedDocument) {
    return <div className="text-lg text-center p-2 ">No Selected Document</div>;
  }

  return (
    <div className="flex flex-col gap-2 overflow-auto max-h-screen max-w-full">
      <div className="text-lg text-center p-2">
        {selectedDocument.displayName}
      </div>
      <Collapsible title="Tags">
        <div className="p-4">
          <div className="flex flex-col flex-wrap gap-4 ">
            {tags ? (
              TAG_SECTIONS.map(section => {
                return (
                  <div key={section.key}>
                    <TagListSection
                      sectionName={section.label}
                      tags={(tags[section.key] ?? []).map((value: any) => ({
                        label: value,
                        count: 1,
                        isChecked: false,
                      }))}
                      readOnly
                    />
                  </div>
                );
              })
            ) : (
              <span className="text-xl">No Tags Found</span>
            )}
          </div>
        </div>
      </Collapsible>
      <Collapsible title="Document" show={true}>
        <div className="w-full overflow-x-auto p-4">
          {selectedDocument.contentType &&
          selectedDocument.contentType.indexOf('pdf') !== -1 ? (
            <PDFDocumentView
              collectionUuid={collectionUuid}
              selectedCitation={selectedCitation}
              selectedDocument={selectedDocument}
            />
          ) : (
            <BlockEditorView
              collectionUuid={collectionUuid}
              documentUuid={selectedDocument.uuid}
            />
          )}
        </div>
      </Collapsible>
    </div>
  );
}

export interface BlockEditorViewProps {
  collectionUuid: string;
  documentUuid: string;
}

export function BlockEditorView({
  collectionUuid,
  documentUuid,
}: BlockEditorViewProps) {
  const {client} = useContext(UserContext);
  const [document, setDocument] = useState<DetailedDocumentResponse>();

  useEffect(() => {
    client?.collection
      .getDocumentDetail(collectionUuid, documentUuid)
      .then(result => {
        if (result && result.result) {
          setDocument(result.result);
        }
      });
  }, [client, collectionUuid, documentUuid]);

  return (
    <BlockEditor
      editorOnly={true}
      document={document}
      collectionUuid={collectionUuid}
      documentUuid={documentUuid}
    />
  );
}

export function PDFDocumentView({
  selectedDocument,
  // sizeTarget,
  selectedCitation,
  collectionUuid,
}: DocumentDetailsProps) {
  const {client} = useContext(UserContext);
  const [documentProxy, setDocumentProxy] = useState<PDFDocumentProxy>();
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [docUrl, setDocUrl] = useState<string | undefined>();
  const [highlights, setHighlights] = useState<HighlightIndex[]>([]);

  useEffect(() => {
    if (selectedCitation) {
      pullTextHighlights(documentProxy, selectedCitation).then(highlight => {
        setHighlights(highlight);
      });
    } else {
      setHighlights([]);
    }
  }, [selectedCitation]);

  useEffect(() => {
    if (selectedDocument) {
      client?.collection
        .getDownloadUrl(collectionUuid, selectedDocument.uuid)
        .then(result => {
          if (result.result) {
            setSelectedPage(1);
            setDocUrl(result.result);
          }
        })
        .catch(console.error);
    }
  }, [selectedDocument]);

  async function onDocumentLoadSuccess(docProxy: PDFDocumentProxy) {
    setDocumentProxy(docProxy);
    setNumPages(docProxy.numPages);
  }

  function hasHighlight(pageIndex: number, itemIndex: number): boolean {
    const length = highlights.length;
    for (let i = 0; i < length; i++) {
      const highlight = highlights[i];
      if (highlight.page === pageIndex && highlight.segment === itemIndex) {
        return true;
      }
    }
    return false;
  }

  const textRenderer = useCallback(
    (textItem: any) => {
      if (
        highlights.length > 0 &&
        hasHighlight(textItem.pageIndex, textItem.itemIndex)
      ) {
        return `<mark>${textItem.str}</mark>`;
      } else {
        return textItem.str;
      }
    },
    [highlights]
  );

  const [pageScale, setPageScale] = useState<number>(1.0);
  // const {width} = useResizeDetector({targetRef: sizeTarget});
  // const pageWidth = Math.min(width ? width : 200, 1024);
  const pageWidth = 600;

  const increment = (increment: number) => {
    setSelectedPage(current => {
      const pageMax = numPages ?? 1;
      return Math.min(Math.max(current + increment, 1), pageMax);
    });
  };

  const loadingIndicator = (
    <div className="w-full items-center flex flex-col">
      <span className="loading loading-spinner loading-lg"></span>
    </div>
  );

  return (
    <div>
      <div className="flex flex-row gap-2 justify-between items-center w-full overflow-auto">
        <div className="flex flex-row gap-1 items-center pl-2">
          <button
            className="btn btn-sm btn-ghost p-1"
            onClick={() => {
              increment(-1);
            }}
          >
            <ArrowUpIcon className="w-4"></ArrowUpIcon>
          </button>
          <button
            className="btn btn-sm btn-ghost p-1"
            onClick={() => {
              increment(1);
            }}
          >
            <ArrowDownIcon className="w-4"></ArrowDownIcon>
          </button>

          <input
            className="input w-12 border-gray-500 border-1 py-1"
            value={selectedPage}
            min={1}
            max={numPages ?? 1}
            onChange={event => {
              const val = event.currentTarget.valueAsNumber;
              setSelectedPage(val);
            }}
            type="number"
          ></input>
          <span>of {numPages}</span>
        </div>
        <div className="flex flex-row gap-1 items-center pr-2">
          <button
            className="btn btn-sm btn-ghost p-1"
            onClick={() => {
              setPageScale(current => {
                const newVal = current - 0.1;
                return Math.max(newVal, 0);
              });
            }}
          >
            <MinusIcon className="w-4"></MinusIcon>
          </button>
          <button
            className="btn btn-sm btn-ghost p-1"
            onClick={() => {
              setPageScale(current => {
                return current + 0.1;
              });
            }}
          >
            <PlusIcon className="w-4"></PlusIcon>
          </button>
        </div>
      </div>
      <div className="mx-auto w-full">
        <Document
          file={docUrl}
          options={DEFAULT_DOC_OPTIONS}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={loadingIndicator}
        >
          <Page
            key={`page_${selectedPage}`}
            pageNumber={selectedPage}
            width={pageWidth}
            customTextRenderer={textRenderer}
            scale={pageScale}
          />
        </Document>
      </div>
    </div>
  );
}

interface CollapsibleProps {
  children: ReactNode;
  title: string;
  show?: boolean;
}

export function Collapsible({children, title, show = false}: CollapsibleProps) {
  const [isShowing, setIsShowing] = useState<boolean>(show);

  return (
    <div className="bg-base-200">
      <div
        className="flex flex-row justify-between p-4 cursor-pointer"
        onClick={() => {
          setIsShowing(showing => {
            return !showing;
          });
        }}
      >
        <div className="text-xl font-medium">{title}</div>
        {isShowing ? (
          <ChevronUpIcon className="w-4"></ChevronUpIcon>
        ) : (
          <ChevronDownIcon className="w-4"></ChevronDownIcon>
        )}
      </div>
      <div className={!isShowing ? 'hidden' : ''}>{children}</div>
    </div>
  );
}

interface HighlightIndex {
  page: number;
  segment: number;
}

async function pullTextHighlights(
  proxy: PDFDocumentProxy | undefined,
  searchText: string
): Promise<HighlightIndex[]> {
  const highlights = [];
  const text = searchText.trim();
  if (proxy) {
    const totalPages = proxy.numPages;
    for (let i = 0; i < totalPages; i++) {
      const page = await proxy.getPage(i + 1);
      const content = await page.getTextContent();
      const contentLength = content.items.length;
      let found = false;
      let consumed = 0;

      for (let j = 0; j < contentLength; j++) {
        if (consumed >= text.length) {
          return highlights;
        }
        const item = content.items[j] as any;

        const searchResult = selectText(item.str, text.substring(consumed));
        if (searchResult.found) {
          consumed += searchResult.consumed;
          found = true;
          highlights.push({page: i, segment: j});
        } else if (consumed >= text.length) {
          break;
        } else if (found && item.str.trim().length > 0) {
          found = false;
          consumed = 0;
          highlights.splice(0, highlights.length);
        }
      }
    }
  }
  return highlights;
}

function isWhitespace(character: string): boolean {
  return ' \t\n\r\v'.indexOf(character) > -1;
}

interface TextFound {
  found: boolean;
  consumed: number;
  start: number;
  end: number;
}

function selectText(text: string, inputSearch: string): TextFound {
  const startLength = inputSearch.length;
  const trimmedInput = inputSearch.trim();

  const textLength = text.length;
  const inputLength = trimmedInput.length;
  let j = 0;
  let i = 0;
  let start = -1;
  let end = -1;
  const startValue = startLength - inputLength;
  let searchCharsUsed = startValue;

  for (i = 0; i < textLength && j < inputLength; i++) {
    const textChar = text.charAt(i).toLowerCase();
    const inputChar = trimmedInput.charAt(j).toLowerCase();
    searchCharsUsed += 1;
    if (textChar === inputChar) {
      j++;
      if (start === -1) {
        start = i;
      }
    } else if (isWhitespace(textChar)) {
      continue;
    } else if (!isWhitespace(textChar) && isWhitespace(inputChar)) {
      for (let k = j; k < inputLength; k++) {
        const nextChar = trimmedInput.charAt(k).toLowerCase();
        searchCharsUsed += 1;
        if (textChar === nextChar) {
          j = k + 1;
          if (start === -1) {
            start = k;
          }
          break;
        } else if (!isWhitespace(nextChar)) {
          j = 0;
          start = -1;

          searchCharsUsed = startValue;
          break;
        }
      }
    } else {
      j = 0;
      start = -1;
      searchCharsUsed = startValue;
    }
  }

  if (j >= inputLength) {
    end = i;
  }

  if (start !== -1) {
    return {
      found: true,
      consumed: searchCharsUsed,
      start: start,
      end: end,
    };
  }
  return {
    found: false,
    consumed: 0,
    end: -1,
    start: -1,
  };
}
