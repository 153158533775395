import {useContext, useEffect, useRef, useState} from 'react';
import {UserContext} from '../context';
import {TextInput} from '../components/modals/TextInput';
import {Link} from 'react-router-dom';
import {FolderIcon, PlusCircleIcon, TrashIcon} from '@heroicons/react/20/solid';
import {GhostRow} from '../components/GhostRow';
import {DateTime} from 'luxon';
import {ConfirmButton} from '../components/buttons/ConfirmButton';
import {CollectionDropUpload} from '../components/utils/CollectionDropUpload';
import {CollectionListing} from '../../bindings/api/CollectionListing';
import {LensType} from '../../bindings/api/LensType';

interface CollectionViewProps {
  filterBy?: LensType;
  defaultCollectionType?: LensType;
  title?: string;
  newLabel?: string;
  onNew?: () => void;
}

export function CollectionListView({
  filterBy,
  defaultCollectionType,
  title,
  newLabel,
  onNew,
}: CollectionViewProps) {
  const {client} = useContext(UserContext);
  const [collections, setCollections] = useState<CollectionListing[]>([]);
  const [loadingCollections, setLoadingCollections] = useState<boolean>(true);
  const [addingCollection, setAddingCollection] = useState<boolean>(false);
  const inputDialog = useRef<HTMLDialogElement>(null);

  const refreshCollections = () => {
    setLoadingCollections(true);
    return client?.collection
      .list(filterBy)
      .then(({result}) => {
        if (result) {
          setCollections(result);
        }
      })
      .finally(() => setLoadingCollections(false));
  };

  useEffect(() => {
    if (!loadingCollections && collections.length === 0) {
      if (onNew) {
        onNew();
      } else {
        inputDialog.current?.showModal();
      }
    }
  }, [collections]);

  useEffect(() => {
    refreshCollections();
  }, []);

  // Maybe add lang to metadata of the collection ....
  const addCollection = (name: string) => {
    setAddingCollection(true);
    return client?.collection
      .create(name, defaultCollectionType || 'General')
      .then(() => refreshCollections())
      .finally(() => setAddingCollection(false));
  };

  return (
    <CollectionDropUpload onUploadFinished={() => refreshCollections()}>
      <div className="flex flex-col gap-4 w-full mb-32 max-w-[960px] mx-auto pt-8">
        <div className="flex flex-row justify-between items-end">
          <div className="flex flex-col">
            <h1 className="text-2xl font-bold">{title || 'Collections'}</h1>
            <div className="text-sm">
              🪄 Drop files or a folder on the page to create a new collection.
            </div>
          </div>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => {
              if (onNew) {
                onNew();
              } else {
                inputDialog.current?.showModal();
              }
            }}
          >
            <PlusCircleIcon className="w-4" />
            New
          </button>
        </div>
        {loadingCollections && collections.length === 0 ? (
          <div className="hero bg-base-300 rounded-xl p-8 text-neutral-500 text-lg min-h-[320px]">
            Loading...
          </div>
        ) : (
          <table className="table h-fit bg-base-300 w-full">
            <thead>
              <tr>
                <th>Name</th>
                <th className="w-48 sm:table-cell hidden">Created At</th>
                <th className="w-6">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {addingCollection ? <GhostRow columns={1} /> : null}
              {collections.map(collection => (
                <tr key={collection.uuid}>
                  <td className="flex flex-row gap-2">
                    <FolderIcon className="w-4 flex-none" />
                    <Link
                      to={
                        collection.collectionType === 'LegalDiscovery'
                          ? `/timeline/${collection.uuid}`
                          : `/collections/${collection.uuid}`
                      }
                      className="link-hover link-primary text-base"
                    >
                      {collection.name}
                    </Link>
                  </td>
                  <td className="text-gray-500 hidden sm:table-cell">
                    {collection.createdAt
                      ? DateTime.fromISO(collection.createdAt).toLocaleString(
                          DateTime.DATETIME_MED
                        )
                      : null}
                  </td>
                  <td>
                    <ConfirmButton
                      title="Delete Collection?"
                      message={`This will permanently delete "${collection.name}"`}
                      className="btn btn-sm btn-ghost btn-circle text-error"
                      confirmLabel="Delete"
                      onConfirm={() =>
                        client?.collection
                          .delete(collection.uuid)
                          .finally(() => refreshCollections())
                      }
                    >
                      <TrashIcon className="w-4" />
                    </ConfirmButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        <TextInput
          dialogRef={inputDialog}
          title={newLabel}
          placeholder="My Documents"
          label="Name"
          setValue={value => addCollection(value)}
        ></TextInput>
      </div>
    </CollectionDropUpload>
  );
}
