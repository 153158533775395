import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  Cog6ToothIcon,
  MagnifyingGlassCircleIcon,
} from '@heroicons/react/24/solid';
import {useContext, useEffect, useRef, useState} from 'react';
import {useLoaderData} from 'react-router-dom';
import {UserContext, WatcherContext} from '../../context';
import {
  DocumentHistogram,
  DocumentSearchResponse,
  SearchUpdate,
  SearchUpdateType,
} from '../../api/client/collection';
import {DateTime} from 'luxon';
import {DocumentResponse} from '../../../bindings/api/DocumentResponse';
import {Timeline} from './TimelineComponent';
import {CollectionDropUpload} from '../../components/utils/CollectionDropUpload';
import {SparklesIcon} from '@heroicons/react/20/solid';
import {DocumentDetails} from './DocumentDetailsView';
import {SelectionFilter} from '../../../bindings/api/SelectionFilter';
import {FilterOption} from '../../../bindings/api/FilterOption';
import {waitForWorkflowCompletion} from '../../api/Client';
import {Observable} from 'rxjs';
import {DataNodeType} from '../../components/talos/types/node';
import {SavedChecklist} from '../../api/client/checklist';
import {
  DateRange,
  TAG_CUSTOM_USER,
  TimelineTagBar,
  ToggledTagMap,
} from './TimelineTagBar';

export function TimelineIcon() {
  return <div>📚</div>;
}

enum Searching {
  AWAIT_INPUT,
  SEARCHING,
  CITATIONS,
}

export interface TimelinePageParams {
  collectionUuid: string;
}

export function TimelinePage() {
  const searchQueryRef = useRef<HTMLInputElement>(null);
  const {collectionUuid} = useLoaderData() as TimelinePageParams;
  const {client} = useContext(UserContext);
  const watcher = useContext(WatcherContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [matterName, setMatterName] = useState<string>('');
  const [histogram, setHistogram] = useState<DocumentHistogram[]>([]);
  const [documents, setDocuments] = useState<DocumentResponse[]>([]);

  const [detailBarVisible, setDetailBarVisible] = useState<boolean>(false);
  const [query, setQuery] = useState<string | null>(null);

  const [rangeFilter, setRangeFilter] = useState<DateRange>({
    start: null,
    end: null,
  });

  const [tags, setTags] = useState<{[key: string]: string[]}>({});
  const [checkedTags, setCheckedTags] = useState<ToggledTagMap>({});
  const [checkedIssues, setCheckedIssues] = useState<ToggledTagMap>({});
  const [checklist, setChecklist] = useState<SavedChecklist>({
    uuid: '',
    version: 0,
    checklist: {
      name: '',
      items: [],
    },
  });

  const [searchStatus, setSearchStatus] = useState<Searching>(
    Searching.AWAIT_INPUT
  );
  const [searchResponse, setSearchResponse] = useState<
    DocumentSearchResponse[] | null
  >(null);
  const [openDocument, setOpenDocument] = useState<DocumentResponse | null>(
    null
  );
  const [selectedCitation, setSelectedCitation] = useState<string | null>(null);

  const refreshCollections = () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    let tags = Object.entries(checkedTags).flatMap(([tag, isChecked]) => {
      const [key, value] = tag.split(':', 2);
      return isChecked ? [{key, value}] : [];
    });

    const issues = Object.entries(checkedIssues).flatMap(
      ([issue_uuid, isChecked]) => {
        return isChecked ? [{key: 'relevant_issues', value: issue_uuid}] : [];
      }
    );

    tags = tags.concat(issues);

    let filter = null;
    if (rangeFilter.start && rangeFilter.end) {
      filter = generateFilter(histogram, rangeFilter.start, rangeFilter.end);
    }

    return client?.collection
      .listDocuments(collectionUuid, {
        tagFilter: tags.length > 0 ? tags : null,
        orderBy: {
          ASC: 'publishedAt',
        },
        filter,
      })
      .then(response => setDocuments(response?.result ?? []))
      .finally(() => setIsLoading(false));
  };

  const handleEditTag = (
    documentUuid: string,
    {tags, removeTags}: {tags?: string[]; removeTags?: string[]}
  ) => {
    const tagsToAdd = tags ?? [];
    const tagsToRemove = removeTags ?? [];

    return client?.collection
      .updateDocument(collectionUuid, documentUuid, {tags, removeTags})
      .then(resp => {
        if (resp.result) {
          const updatedDoc: DocumentResponse = resp.result;
          // update tag list
          setTags(tags => {
            const update: {[key: string]: string[]} = {...tags};
            update[TAG_CUSTOM_USER] = update[TAG_CUSTOM_USER].concat(tagsToAdd);
            update[TAG_CUSTOM_USER] = update[TAG_CUSTOM_USER].flatMap(val =>
              tagsToRemove.includes(val) ? [] : [val]
            );
            return update;
          });

          // update document
          setDocuments(docs => {
            let update = [...docs];
            update = update.map(doc =>
              doc.uuid === documentUuid ? updatedDoc : doc
            );
            return update;
          });
        }
      });
  };

  const handleAddTag = (documentUuid: string, newTag: string) => {
    return handleEditTag(documentUuid, {tags: [newTag]});
  };

  const handleDeleteTag = (documentUuid: string, tag: string) => {
    return handleEditTag(documentUuid, {removeTags: [tag]});
  };

  const handleSearch = (query: string) => {
    setSearchResponse(null);
    setSearchStatus(Searching.AWAIT_INPUT);
    setQuery(query);

    if (query.length === 0) {
      return;
    }

    setSearchStatus(Searching.SEARCHING);
    const searchStreamCallback = (update: SearchUpdate) => {
      if (update.updateType === SearchUpdateType.SearchResult) {
        setSearchStatus(Searching.CITATIONS);
      } else if (update.updateType === SearchUpdateType.SearchEnd) {
        setSearchStatus(Searching.AWAIT_INPUT);
      }
      if (update.update && update.update.result) {
        setSearchResponse(update.update.result);
      }
    };

    const tags = Object.entries(checkedTags).flatMap(([tag, isChecked]) => {
      const [key, value] = tag.split(':', 2);
      return isChecked ? [[key, value] as [string, string]] : [];
    });

    const searchFilter = generateSearchFilter(
      histogram,
      rangeFilter.start,
      rangeFilter.end
    );
    return client?.collection
      .search(
        collectionUuid,
        query,
        tags,
        searchFilter.start,
        searchFilter.end,
        {
          searchStreamCallback,
        }
      )
      .catch(console.error);
  };

  const triggerReprocess = () => {
    if (client) {
      return client.workflow
        .trigger('LegalCaseProcessing', {
          type: DataNodeType.Collection,
          data: {
            collection: collectionUuid,
          },
        })
        .then(response => {
          if (response && response.result) {
            watcher.watchWorkflow('Legal Case Processing', response.result, {
              onComplete: () => {
                refreshCollections()?.catch();
              },
            });
          }
        });
    }
  };

  // Refresh collection whenever tags are checked or the time range has been updated.
  useEffect(() => {
    refreshCollections()?.catch();
  }, [checkedIssues, checkedTags, rangeFilter]);

  useEffect(() => {
    if (!client || !collectionUuid) {
      return;
    }

    const update = async () => {
      // Load collection data
      setIsLoading(true);
      const collectionResponse = await client?.collection.get(collectionUuid);
      if (collectionResponse && collectionResponse.result) {
        const result = collectionResponse.result;
        setMatterName(result.name);
      }

      // Load date histogram for docs
      const resp = await client?.collection.histogram(collectionUuid);
      if (resp && resp.result) {
        setHistogram(resp.result);
      }

      // Load checklist
      await client?.checklist.getByCollection(collectionUuid).then(resp => {
        if (resp) {
          setChecklist(resp);
        }
      });

      return client?.collection
        .listDocuments(collectionUuid, {
          tagFilter: null,
          orderBy: {ASC: 'publishedAt'},
          filter: null,
        })
        .then(response => {
          setDocuments(response?.result ?? []);
        })
        .finally(() => setIsLoading(false));
    };

    // Check if a workflow is already running and make sure the display
    // refreshes when the workflow is done.
    client.workflow
      .listRuns({
        collectionUuid,
        status: ['Queued', 'Processing', 'Started'],
        limit: null,
      })
      .then(runs => {
        if (runs && runs.result) {
          for (const run of runs.result) {
            watcher.watchWorkflow(
              run.workflowDefinition.name ?? 'Legal Processing',
              run.uuid,
              {
                onComplete: () => {
                  refreshCollections()?.catch();
                },
              }
            );
          }
        }
      });

    update().catch(console.error);
  }, [client, collectionUuid]);

  return (
    <CollectionDropUpload
      collection={collectionUuid}
      onUploadFinished={(files, docs) => {
        client?.workflow
          .trigger('LegalCaseProcessing', {
            type: 'documentList',
            data: {
              collection: collectionUuid,
              selected_documents: docs,
            },
          })
          .then(result => {
            if (result.result) {
              watcher.watchWorkflow('Legal Case Processing', result.result, {
                onComplete: () => {
                  refreshCollections()?.catch(console.error);
                },
              });
            }
          });
        refreshCollections();
      }}
    >
      <div className="w-full">
        <div className="flex flex-col p-4 bg-base-200 gap-4 border-b border-slate-600">
          <div className="flex flex-row items-center justify-between">
            <div className="font-bold text-accent text-lg">{matterName}</div>
            <div className="flex flex-row items-center gap-2">
              {documents.length === 0 ? (
                <div>no documents</div>
              ) : (
                <span className="text-accent">
                  {documents.length} documents
                </span>
              )}
              <div
                className="dropdown dropdown-bottom dropdown-end"
                data-tip="Download"
              >
                <div
                  tabIndex={0}
                  role="button"
                  className={'btn btn-neutral btn-sm'}
                >
                  <Cog6ToothIcon className="w-4" />
                </div>
                <ul
                  tabIndex={0}
                  className="p-2 shadow menu dropdown-content z-[1] bg-neutral rounded-box w-48 border-neutral-600 border"
                >
                  <li className="text-sm">
                    <a onClick={() => triggerReprocess()}>Reprocess</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <form
            onSubmit={e => {
              e.preventDefault();
              handleSearch(searchQueryRef.current?.value ?? '');
              return false;
            }}
          >
            <div className="join w-full">
              <label className="join-item input input-bordered items-center flex flex-row gap-2 w-full">
                {searchStatus === Searching.SEARCHING ? (
                  <span className="loading loading-spinner loading-md"></span>
                ) : searchStatus === Searching.AWAIT_INPUT ? (
                  <MagnifyingGlassCircleIcon className="w-6 text-primary" />
                ) : searchStatus === Searching.CITATIONS ? (
                  <SparklesIcon className="w-6 text-primary animate-spin" />
                ) : (
                  <MagnifyingGlassCircleIcon className="w-6 text-primary" />
                )}

                <input
                  ref={searchQueryRef}
                  type="text"
                  className="bg-inherit grow"
                  placeholder="search or ask a question"
                />
              </label>
              <button
                className="btn btn-primary join-item rounded-r"
                type="submit"
              >
                Search
              </button>
            </div>
          </form>
        </div>
        <div className="flex flex-row items-start divide-x divide-solid divide-slate-600">
          <div className="w-1/5 pt-4 px-4 lg:px-8 flex flex-col gap-4">
            <div>
              <div className="font-bold text-lg text-accent">Date</div>
              <TimelineTagBar
                checkedIssues={checkedIssues}
                checkedTags={checkedTags}
                checklist={checklist}
                collectionUuid={collectionUuid}
                histogram={histogram}
                setCheckedIssues={setCheckedIssues}
                setCheckedTags={setCheckedTags}
                setRangeFilter={setRangeFilter}
                setTags={setTags}
                tags={tags}
              />
            </div>
          </div>
          <div className="flex flex-row gap-1 w-full">
            <div
              className={`flex flex-row  ${
                detailBarVisible ? 'w-3/5' : 'w-full'
              }`}
            >
              {documents.length > 0 ? (
                <Timeline
                  checklist={checklist}
                  documents={documents}
                  searchResults={searchResponse}
                  searchQuery={query}
                  onAddTag={handleAddTag}
                  onDeleteTag={handleDeleteTag}
                  onDocumentSelect={uuid => {
                    setSelectedCitation(null);
                    setOpenDocument(uuid);
                    setDetailBarVisible(true);
                  }}
                  onCitationSelect={citation => {
                    setSelectedCitation(citation);
                  }}
                />
              ) : (
                <div className="text-lg p-8">
                  🪄 Drop files or a folder on the page to add to timeline.
                </div>
              )}
            </div>
            <div
              className={`flex flex-col sticky top-0 h-screen ${
                detailBarVisible ? 'flex-grow' : 'flex-shrink'
              } ${detailBarVisible ? 'w-2/5' : ''}`}
            >
              <div
                className={`flex flex-row-reverse absolute p-2 ${
                  detailBarVisible ? 'left-2' : 'right-0'
                }`}
              >
                <button
                  className="btn btn-sm btn-square btn-primary z-50"
                  onClick={() => setDetailBarVisible(!detailBarVisible)}
                >
                  {detailBarVisible ? (
                    <ChevronDoubleRightIcon className="w-4" />
                  ) : (
                    <ChevronDoubleLeftIcon className="w-4" />
                  )}
                </button>
              </div>
              {detailBarVisible ? (
                <DocumentDetails
                  collectionUuid={collectionUuid}
                  selectedDocument={openDocument}
                  selectedCitation={selectedCitation}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </CollectionDropUpload>
  );
}

function generateSearchFilter(
  histogram: DocumentHistogram[],
  start: DateTime | null,
  end: DateTime | null
): DateRange {
  let histStart;
  let histEnd;
  const range: DateRange = {
    start: null,
    end: null,
  };

  if (histogram && histogram.length > 0) {
    histStart = DateTime.fromISO(histogram[0].date);
    histEnd = DateTime.fromISO(histogram[histogram.length - 1].date);
  }

  if (
    (histStart && start && histStart?.toISODate() !== start.toISODate()) ||
    !histStart
  ) {
    range.start = start;
  }
  if ((histEnd && end && histEnd.toISODate() !== end.toISODate()) || !histEnd) {
    range.end = end;
  }

  return range;
}

function generateFilter(
  histogram: DocumentHistogram[],
  start: DateTime,
  end: DateTime
): SelectionFilter | null {
  let histStart;
  let histEnd;

  if (histogram && histogram.length > 0) {
    histStart = DateTime.fromISO(histogram[0].date);
    histEnd = DateTime.fromISO(histogram[histogram.length - 1].date);
  }

  const filter: SelectionFilter = {
    must: null,
    should: null,
  };
  if (
    (histStart && histStart?.toISODate() !== start.toISODate()) ||
    !histStart
  ) {
    filter.must = [
      {
        attribute: 'publishedAt',
        gteq: {
          value: start.toISO() || '',
        },
        eq: null,
        gt: null,
        lt: null,
        lteq: null,
        must: null,
        neq: null,
        should: null,
      },
    ];
  }

  if ((histEnd && histEnd.toISODate() !== end.toISODate()) || !histEnd) {
    const attrFilter: FilterOption = {
      attribute: 'publishedAt',
      lteq: {
        value: end.toISO() || '',
      },
      gteq: null,
      eq: null,
      gt: null,
      lt: null,
      must: null,
      neq: null,
      should: null,
    };

    if (filter.must) {
      filter.must.push(attrFilter);
    } else {
      filter.must = [attrFilter];
    }
  }

  if (filter.must) {
    return filter;
  } else {
    return null;
  }
}
