import {ApiResponse} from '../../../bindings/api/ApiResponse';
import {SightglassClient} from '../Client';

export interface Checklist {
  version: number;
  checklist: ChecklistDefinition;
  collection_uuid?: string;
}

export interface SavedChecklist {
  uuid: string;
  version: number;
  checklist: ChecklistDefinition;
}

export interface ChecklistDefinition {
  name: string;
  items: ChecklistItem[];
}

export interface ChecklistItem {
  uuid: string;
  title: string;
  description: string;
}

export class ChecklistEndpoints {
  client: SightglassClient;
  module: string;

  constructor(client: SightglassClient) {
    this.client = client;
    this.module = 'checklist';
  }

  async list(): Promise<ApiResponse<SavedChecklist[]> | null> {
    return this.client.getJson<ApiResponse<SavedChecklist[]>>('checklist');
  }

  async save(
    checklist: Checklist
  ): Promise<ApiResponse<SavedChecklist> | null> {
    return this.client.postJson<SavedChecklist>('checklist', checklist);
  }

  async delete(uuid: string): Promise<ApiResponse<number> | null> {
    return this.client.deleteJson<ApiResponse<number>>(`checklist/${uuid}`);
  }

  async update(
    uuid: string,
    checklist: Checklist
  ): Promise<ApiResponse<SavedChecklist> | null> {
    return this.client.putJson<SavedChecklist>(`checklist/${uuid}`, checklist);
  }

  async getByCollection(
    collectionUuid: string
  ): Promise<SavedChecklist | null> {
    return this.client
      .getJson<ApiResponse<SavedChecklist[]>>('checklist', {
        collection_uuid: collectionUuid,
      })
      .then(resp => (resp.result ? resp.result[0] : null));
  }
}
