import {
  Subject,
  catchError,
  from,
  mergeMap,
  of,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import {SightglassClient} from './api/Client';
import {WatcherContext, WorkflowCallback} from './context';
import {Id as ToastId, toast} from 'react-toastify';
import {ApiResponse} from '../bindings/api/ApiResponse';
import {UserWorkflowInstanceSummary} from '../bindings/api/UserWorkflowInstanceSummary';

interface WorkflowWatch {
  toast: ToastId;
  uuid: string;
  onComplete: WorkflowCallback[];
}

export class Watcher implements WatcherContext {
  client: SightglassClient | undefined;
  watched: WorkflowWatch[] = [];

  public watchWorkflow(
    name: string,
    run_uuid: string,
    callback?: WorkflowCallback
  ): void {
    if (!this.client) {
      return;
    }

    // Ignore workflows we're already watching.
    const isWatched = this.watched.find(x => x.uuid === run_uuid);
    if (isWatched) {
      if (callback) {
        isWatched.onComplete.push(callback);
      }
      return;
    }

    const client = this.client;
    const finished = new Subject<boolean>();
    const toastRef = toast(`Running Workflow ${name}...`, {
      progress: 0.1,
      autoClose: false,
    });

    const watchers = [];
    if (callback) {
      watchers.push(callback);
    }

    this.watched.push({toast: toastRef, uuid: run_uuid, onComplete: watchers});

    // If the dialog is closed stop watching
    toast.onChange(item => {
      if (
        item.id === toastRef &&
        item.status === 'removed' &&
        finished.observed
      ) {
        console.debug('Stopping workflow status query');
        finished.next(true);
        finished.complete();
      }
    });

    let errorCount = 0;
    timer(500, 2000)
      .pipe(
        takeUntil(finished),
        mergeMap(() => {
          return from(client.getWorkflowInstance(run_uuid));
        }),
        catchError((err, caught) => {
          console.error('Caught error checking workflow status', err);
          if (errorCount >= 3) {
            return of({
              time: 0,
              status: 'error',
              result: null,
            } as ApiResponse<UserWorkflowInstanceSummary>);
          } else {
            errorCount++;
            return caught;
          }
        })
      )
      .pipe(
        tap(val => {
          if (val.result) {
            // reset error count after successful response
            errorCount = 0;
            this.updateRunningWorkflow(val.result, toastRef);
            if (
              val.result.status === 'Failed' ||
              val.result.status === 'Complete'
            ) {
              finished.next(true);
              finished.complete();
              this.cleanupWatcher(run_uuid);
            }
          } else if (val.status === 'error') {
            finished.next(true);
            finished.complete();
            toast('Failed to get status from the server. Please refresh.', {
              theme: 'colored',
              type: 'error',
            });
            toast.update(toastRef, {progress: 1});
            toast.done(toastRef);
            this.cleanupWatcher(run_uuid);
          }
        })
      )
      .subscribe(() => {});
  }

  public setClient(client: SightglassClient): void {
    this.client = client;

    // Do a quick check to see if there are any running workflows & watch them
    this.client.workflow
      .listRuns({
        status: ['Queued', 'Processing', 'Started'],
        limit: null,
        collectionUuid: null,
      })
      .then(resp => {
        if (resp.result) {
          resp.result.map(workflowRun => {
            this.watchWorkflow(
              workflowRun.workflowDefinition.name ?? 'Untitled Workflow',
              workflowRun.uuid
            );
          });
        }
      })
      .catch(console.error);
  }

  cleanupWatcher(run_uuid: string) {
    const index = this.watched.findIndex(x => x.uuid === run_uuid);
    if (index >= 0) {
      const watcher = this.watched[index];
      for (const callback of watcher.onComplete) {
        callback.onComplete(run_uuid);
      }
      this.watched.splice(index, 1);
    }
  }

  updateRunningWorkflow(result: any, toastRef: ToastId) {
    if (result) {
      const summary = result;
      if (['Failed', 'Complete'].includes(summary.status)) {
        toast.update(toastRef, {progress: 1});
        toast.done(toastRef);

        if (summary.status === 'Failed') {
          toast(`Failed to run workflow ${summary.workflowDefinition.name}`, {
            theme: 'colored',
            type: 'error',
          });
        }
      } else {
        const progress = summary.tasksComplete / summary.taskCount;
        toast.update(toastRef, {progress});
      }
    }
  }
}
