import {
  ArrowPathIcon,
  BoltIcon,
  CheckBadgeIcon,
  EllipsisHorizontalIcon,
  ExclamationCircleIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';
import {WorkflowSummary} from '../../api/Client';
import {useCallback, useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Paginator} from '../../components/pager';
import {Link} from 'react-router-dom';
import {UserContext} from '../../context';
import {DateTime} from 'luxon';
import {Popover} from 'react-tiny-popover';

const WORKFLOW_EXAMPLES = [
  {
    title: 'Podcast Subtitles and Show Notes',
    description:
      'Transcribe multi-track audio into a WebVTT, generate show notes ' +
      'and extract any products mentioned.',
    path: '/workflows/examples/podcast-workflow',
  },
  {
    title: 'Yelp Review Analysis',
    description:
      'Analyze the content of the Yelp review by flagging whether the ' +
      'reviewer happy, satisfied, dissatisfied, or angry; and extracting any ' +
      'complaints or praises for reference.',
    path: '/workflows/examples/sentiment-analysis',
  },
  {
    title: 'Read and Summarize a Webpage',
    description:
      'Read a wikipedia page and create a short summary of what the page talks about ' +
      'along with a list of topics that are mentioned.',
    path: '/workflows/examples/webpage-summary',
  },
  {
    title: 'Extract Data from Content',
    description:
      'Extract structured data from an unformatted block of text content',
    path: '/workflows/examples/extract-spell-data',
  },
];

export function WorkflowDashboard() {
  const [workflows, setWorkflows] = useState<WorkflowSummary[]>([]);
  const [page, setPage] = useState<number>(0);
  const [numberPages, setNumberPages] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const {client} = useContext(UserContext);
  const nav = useNavigate();

  const refreshPage = useCallback(
    (page: number) => {
      client?.getWorkflowOverview(page).then(workflow => {
        setIsLoading(false);
        const workflow_page = workflow.result;
        setWorkflows(workflow.result?.workflows ?? []);
        if (workflow_page) {
          const page_count = Math.ceil(
            workflow_page.totalCount / workflow_page.pageSize
          );
          setNumberPages(page_count);
        }
      });
    },
    [client]
  );

  useEffect(() => {
    refreshPage(page);
  }, [client, page, refreshPage]);

  // Create a new empty workflow and navigate to it.
  const handleNewWorkflow = () => {
    return client?.workflow
      .create({name: 'Untitled Workflow', flow: {steps: []}})
      .then(workflow => {
        if (workflow.result) {
          nav(`/workflows/${workflow.result.workflow.uuid}`);
        }
      });
  };

  const handleSelectPage = (page: number) => {
    setPage(page);
  };

  return (
    <div className="flex flex-col gap-4 w-full mb-32 max-w-[960px] mx-auto pt-8">
      <div className="flex flex-row justify-between">
        <h1 className="text-2xl font-bold">Workflows</h1>
        <button
          className="btn btn-primary btn-sm"
          onClick={() => handleNewWorkflow()}
        >
          <PlusCircleIcon className="w-4" />
          New Workflow
        </button>
      </div>
      <div className="flex flex-wrap gap-4 min-h-[320px]">
        {!isLoading && workflows.length === 0 ? (
          <div className="hero bg-base-300 rounded-xl p-8 text-sm text-neutral-500">
            <button
              className="btn btn-primary btn-sm"
              onClick={() => handleNewWorkflow()}
            >
              <PlusCircleIcon className="w-4" />
              New Workflow
            </button>
          </div>
        ) : null}
        {isLoading ? (
          <div className="hero bg-base-300 rounded-xl p-8 text-neutral-500 text-lg">
            Loading...
          </div>
        ) : null}
        {!isLoading && workflows.length > 0 ? (
          <table className="table h-fit bg-base-300">
            <thead>
              <tr>
                <th>Status</th>
                <th>Workflow Name</th>
                <th colSpan={4}>Run Summary</th>
                <th>Last Ran</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {workflows.map(workflow => {
                return (
                  <WorkflowTile
                    key={workflow.uuid}
                    workflow={workflow}
                    onClick={() => {
                      nav(`/workflows/${workflow.uuid}`);
                    }}
                    onDelete={() => {
                      if (client) {
                        return client?.workflow
                          .delete(workflow.uuid)
                          .then(result => {
                            console.error('result ', result);
                            if (result.status === 'ok') {
                              refreshPage(page);
                            }
                          });
                      } else {
                        return Promise.reject('No configured client');
                      }
                    }}
                  />
                );
              })}
            </tbody>
          </table>
        ) : null}
      </div>
      {numberPages > 1 ? (
        <Paginator
          currentPage={page}
          numPages={numberPages}
          onSelectPage={handleSelectPage}
        />
      ) : null}
      <div className="mt-8 flex flex-col gap-4">
        <div>
          <h2 className="text-xl font-bold">Example Workflows</h2>
          <div className="text-sm">
            Not sure where to start? Try one of our examples.
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {WORKFLOW_EXAMPLES.map(x => (
            <WorkflowExample
              key={x.title}
              title={x.title}
              description={x.description}
              path={x.path}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

interface WorkflowExampleProps {
  title: string;
  description: string;
  path: string;
}

export function WorkflowExample({
  title,
  description,
  path,
}: WorkflowExampleProps) {
  return (
    <Link
      className="card card-compact w-full border-2 border-base-300 hover:shadow-xl"
      to={path}
    >
      <div className="card-body">
        <h2 className="card-title text-white">
          <div>{title}</div>
        </h2>
        <p className="text-sm text-gray-400">{description}</p>
        <div className="card-actions justify-end mt-4">
          <button className="btn btn-sm btn-primary">View</button>
        </div>
      </div>
    </Link>
  );
}

export interface WorkflowTileProperties {
  workflow: WorkflowSummary;
  onClick: () => void;
  onDelete: () => Promise<any>;
}

export function WorkflowTile({
  workflow,
  onClick,
  onDelete,
}: WorkflowTileProperties) {
  const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false);
  const popover = (
    <div className="card bg-error text-error-content">
      <div className="card-body p-4 text-sm">
        {workflow.lastRunErrorMsg ?? ''}
      </div>
    </div>
  );

  return (
    <tr
      className="text-md hover:bg-base-300 hover:cursor-pointer"
      onClick={onClick}
    >
      <td className="w-8 hover:cursor-pointer">
        <Popover isOpen={showErrorPopup} content={popover}>
          <div
            onMouseOver={() =>
              setShowErrorPopup(workflow.lastRunStatus === 'Failed')
            }
            onMouseOut={() => setShowErrorPopup(false)}
          >
            {workflow.lastRunStatus === 'Failed' ? (
              <ExclamationCircleIcon className="w-6 mx-auto text-error" />
            ) : workflow.lastRunStatus === 'Complete' ? (
              <CheckBadgeIcon className="w-6 mx-auto text-success" />
            ) : (
              <span />
            )}
          </div>
        </Popover>
      </td>
      <td className="hover:text-primary">{workflow.name}</td>
      <td className="w-6">
        <div
          className="gap-1 flex flex-row items-center tooltip place-content-end"
          data-tip="Total # of runs"
        >
          {workflow.numTotal}
          <BoltIcon className="w-3" />
        </div>
      </td>
      <td className="w-6">
        <div
          className="gap-1 flex flex-row items-center tooltip place-content-end"
          data-tip="# of runs completed"
        >
          {workflow.numCompleted}
          <CheckBadgeIcon className="w-3 text-success" />
        </div>
      </td>
      <td className="w-6">
        <div
          className="gap-1 flex flex-row items-center tooltip place-content-end"
          data-tip="# of runs in progress"
        >
          {workflow.numQueued}
          <ArrowPathIcon className="w-3" />
        </div>
      </td>
      <td className="w-6">
        <div
          className="gap-1 flex flex-row items-center tooltip place-content-end"
          data-tip="# of runs failed"
        >
          {workflow.numFailed}
          <ExclamationCircleIcon className="w-3 text-error" />
        </div>
      </td>
      <td>
        {workflow.lastRunFinishedOn
          ? DateTime.fromISO(workflow.lastRunFinishedOn).toLocaleString(
              DateTime.DATETIME_MED
            )
          : null}
      </td>
      <td>
        <WorkflowActionMenu uuid={workflow.uuid} onDelete={onDelete} />
      </td>
    </tr>
  );
}

interface WorkflowActionMenuProps {
  uuid: string;
  onDelete: () => Promise<any>;
}

function WorkflowActionMenu({onDelete}: WorkflowActionMenuProps) {
  const [running, setRunning] = useState<boolean>(false);

  const deleteWorkflow = () => {
    setRunning(true);
    onDelete()
      .catch(console.error)
      .finally(() => {
        setRunning(false);
      });
  };

  if (running) {
    return <ArrowPathIcon className="w-4 animate-spin" />;
  } else {
    return (
      <div className="dropdown dropdown-left">
        <div
          tabIndex={0}
          role="button"
          className="btn btn-circle btn-sm bg-inherit"
          onClick={event => {
            event.stopPropagation();
          }}
        >
          <EllipsisHorizontalIcon className="w-4" />
        </div>
        <ul
          tabIndex={0}
          className="dropdown-content z-[1] menu p-2 shadow bg-stone-900 rounded-box w-52 text-sm"
        >
          <li>
            <a
              className="text-sm text-error"
              onClick={event => {
                event.stopPropagation();
                (document.activeElement as HTMLElement).blur();
                deleteWorkflow();
              }}
            >
              <TrashIcon className="w-4" />
              Delete
            </a>
          </li>
        </ul>
      </div>
    );
  }
}
