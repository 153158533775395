import {ExtractNode} from '../../../../../bindings/ExtractNode';
import {Step} from '../../../../../bindings/Step';
import {StepData} from '../../../../../bindings/StepData';
import {EditableTextarea} from '../../editable';
import {NodeBodyProps, NodeDefinition} from '../../nodes';
import {BoltIcon} from '@heroicons/react/20/solid';

export class ExtractEmailResponse implements NodeDefinition {
  public defaultExtractData: ExtractNode = {
    peek: null,
    fastExtract: null,
    query:
      "Respond to this email as politely as possible and let them know we're working on it.",
    schema: {
      type: 'object',
      $schema: 'https://json-schema.org/draft/2020-12/schema',
      required: ['emailResponse'],
      properties: {emailResponse: {type: 'string'}},
    },
    imageExtract: null,
    template: {
      template: '{{ emailResponse }}',
      varMapping: {emailResponse: 'emailResponse'},
    },
  };
  public defaultData: StepData = {
    nodeType: 'Extract',
    data: this.defaultExtractData,
  };
  public nodeSelectionLabel = 'Email Response';
  public nodeLabel = 'Create an email response';
  public getNodeIcon(className?: string): JSX.Element {
    return <BoltIcon className={className} />;
  }

  public renderNode(baseProps: NodeBodyProps): JSX.Element {
    return <ExtractEmailResponseView {...baseProps} />;
  }

  public createStep(): Step {
    const newNode: Step = {
      uuid: crypto.randomUUID(),
      label: this.nodeLabel,
      nodeType: 'Extract',
      templateId: 'ExtractEmailResponse',
      data: this.defaultExtractData,
      parentNode: false,
      inputSource: null,
    };
    return newNode;
  }
}
export const EXTRACT_EMAIL_RESPONSE_NODE_DEFINITION =
  new ExtractEmailResponse();

function ExtractEmailResponseView({
  data,
  onUpdateData = () => {},
}: NodeBodyProps) {
  const nodeData = data as ExtractNode;
  const updateNodeData = (data: Partial<ExtractNode>) => {
    onUpdateData({
      ...nodeData,
      query: data.query ?? nodeData.query,
      template: data.template ?? nodeData.template,
    });
  };
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col">
        <EditableTextarea
          label="How should I respond?"
          placeholder="Edit"
          data={nodeData.query}
          onChange={newValue => updateNodeData({query: newValue})}
        />
      </div>
    </div>
  );
}
