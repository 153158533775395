import {Paginator} from '../../components/pager';
import {UserContext} from '../../context';
import {useState, useContext, useEffect, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {CheckBadgeIcon, ExclamationCircleIcon} from '@heroicons/react/20/solid';
import {DateTime} from 'luxon';
import {Popover} from 'react-tiny-popover';
import {UserWorkflowInstanceSummary} from '../../../bindings/api/UserWorkflowInstanceSummary';

export interface WorkflowRunsProps {
  workflowUuid: string;
}

export default function WorkflowRunList({workflowUuid}: WorkflowRunsProps) {
  const [workflowInstances, setWorkflowInstances] = useState<
    UserWorkflowInstanceSummary[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [numberPages, setNumberPages] = useState<number>(0);
  const {client} = useContext(UserContext);
  const nav = useNavigate();

  const refreshPage = useCallback(
    (page: number, workflowUuid: string): Promise<void> | undefined => {
      return client?.workflow
        .listWorkflowRuns(workflowUuid, page)
        .then(response => {
          if (response.result) {
            const totalCount = response.result.totalCount;
            const pageSize = response.result.pageSize;
            const pageCount = Math.ceil(totalCount / pageSize);

            setNumberPages(pageCount);
            if (response.result.instances) {
              setWorkflowInstances(response.result.instances);
            }
          }
        })
        .catch(console.error);
    },
    [client]
  );

  useEffect(() => {
    setLoading(true);
    refreshPage(page, workflowUuid)?.finally(() => {
      setLoading(false);
    });
  }, [client, workflowUuid, page, refreshPage]);

  const navToRun = useCallback(
    (runUuid: string) => {
      const run = workflowInstances.find(x => x.uuid === runUuid);
      if (run) {
        nav(`/workflows/${run.workflowDefinition.uuid}/run/${runUuid}`);
      }
    },
    [nav, workflowInstances]
  );

  return (
    <div className="flex flex-col gap-4 w-full">
      <div className="flex flex-wrap gap-4 min-h-[320px]">
        {loading ? (
          <div className="hero bg-base-300 rounded-xl p-8 text-neutral-500 text-lg">
            Loading...
          </div>
        ) : (
          <WorkflowRunTable
            workflowRuns={workflowInstances}
            onClick={navToRun}
          />
        )}
      </div>
      {numberPages > 1 ? (
        <Paginator
          currentPage={page}
          numPages={numberPages}
          onSelectPage={newNumber => {
            setPage(newNumber);
          }}
        />
      ) : null}
    </div>
  );
}

interface WorkflowRunTable {
  workflowRuns: UserWorkflowInstanceSummary[];
  onClick?: (runUuid: string) => void;
  showWorkflowName?: boolean;
}

export function WorkflowRunTable({
  workflowRuns,
  onClick = () => {},
  showWorkflowName = false,
}: WorkflowRunTable) {
  return (
    <table className="table h-fit bg-base-300">
      <thead>
        <tr>
          <th>Status</th>
          {showWorkflowName && <th>Workflow Name</th>}
          <th>Start Time</th>
          <th>End Time</th>
          <th>Duration</th>
        </tr>
      </thead>
      <tbody>
        {workflowRuns.map(run => (
          <WorkflowInstanceRow
            key={run.uuid}
            workflowInstance={run}
            onClick={() => onClick(run.uuid)}
            showWorkflowName={showWorkflowName}
          />
        ))}
      </tbody>
    </table>
  );
}

export interface WorkflowInstanceRowProps {
  workflowInstance: UserWorkflowInstanceSummary;
  onClick?: () => void;
  showWorkflowName?: boolean;
}

export function WorkflowInstanceRow({
  workflowInstance,
  onClick = () => {},
  showWorkflowName = false,
}: WorkflowInstanceRowProps) {
  const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false);
  const popover = (
    <div className="card bg-error text-error-content">
      <div className="card-body p-4 text-sm">
        {workflowInstance.errorMsg ?? ''}
      </div>
    </div>
  );

  const created = DateTime.fromISO(workflowInstance.createdAt);
  let finished;
  let duration;
  if (workflowInstance.finishedOn) {
    finished = DateTime.fromISO(workflowInstance.finishedOn);
    duration = finished.diff(created);
  }

  return (
    <tr
      className="text-md hover:cursor-pointer hover:bg-slate-800"
      onClick={onClick}
    >
      <td className="w-8 hover:cursor-pointer">
        <Popover isOpen={showErrorPopup} content={popover}>
          <div
            onMouseOver={() =>
              setShowErrorPopup(workflowInstance.status === 'Failed')
            }
            onMouseOut={() => setShowErrorPopup(false)}
          >
            {workflowInstance.status === 'Failed' ? (
              <ExclamationCircleIcon className="w-6 mx-auto text-error" />
            ) : workflowInstance.status === 'Complete' ? (
              <CheckBadgeIcon className="w-6 mx-auto text-success" />
            ) : (
              <span />
            )}
          </div>
        </Popover>
      </td>
      {showWorkflowName && (
        <td className="md:table-cell">
          {workflowInstance.workflowDefinition.name}
        </td>
      )}
      <td className="xs:hidden sm:hidden md:table-cell">
        {created.toLocaleString(DateTime.DATETIME_MED)}
      </td>
      <td className="xs:table-cell sm:table-cell md:hidden">
        {created.toLocaleString(DateTime.DATETIME_SHORT)}
      </td>
      <td className="xs:hidden sm:hidden md:table-cell">
        {finished ? finished.toLocaleString(DateTime.DATETIME_MED) : null}
      </td>
      <td className="xs:table-cell sm:table-cell md:hidden">
        {finished ? finished.toLocaleString(DateTime.DATETIME_SHORT) : null}
      </td>
      <td>
        {duration ? duration.toISOTime({suppressMilliseconds: true}) : null}
      </td>
    </tr>
  );
}
