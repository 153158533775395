// Essentially a wrapper around the collection list view, with only the legal
import {useNavigate} from 'react-router-dom';
import {CollectionListView} from '../CollectionListView';
import {useContext} from 'react';
import {UserContext} from '../../context';

export function TimelineList() {
  const nav = useNavigate();
  const {client} = useContext(UserContext);

  const onNewTimeline = () => {
    if (client) {
      return client.collection
        .create('Untitled Matter', 'LegalDiscovery')
        .then(response => {
          // Also create an empty checklist for issues & assign to this collection.
          if (response.result) {
            return client.checklist
              .save({
                version: 1,
                checklist: {
                  name: 'Issues',
                  items: [],
                },
                collection_uuid: response.result.uuid,
              })
              .then(() => {
                response.result
                  ? nav(`/timeline/${response.result.uuid}/edit`)
                  : console.error(response);
              });
          }
        })
        .catch(err => console.error(err));
    }
  };

  return (
    <CollectionListView
      filterBy="LegalDiscovery"
      defaultCollectionType="LegalDiscovery"
      title="Timelines"
      newLabel="New Matter"
      onNew={onNewTimeline}
    />
  );
}
