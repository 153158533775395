import {fetchEventSource} from '@microsoft/fetch-event-source';
import {
  API_ENDPOINT,
  SightglassClient,
  SystemChatMessage,
  UserWorkflow,
  WorkflowResponse,
  WorkflowSuggestionCallback,
  WorkflowTaskResponse,
  create_headers,
} from '../Client';
import {ApiResponse} from '../../../bindings/api/ApiResponse';
import {DataNode} from '../../../bindings/DataNode';
import {UserWorkflowSummary} from '../../../bindings/api/UserWorkflowSummary';
import {GetWorkflowRunsQuery} from '../../../bindings/api/GetWorkflowRunsQuery';
import {UserWorkflowInstanceSummary} from '../../../bindings/api/UserWorkflowInstanceSummary';
import {WorkflowInstancePage} from '../../../bindings/api/WorkflowInstancePage';
import {AxiosError} from 'axios';
import {ToastOptions, toast} from 'react-toastify';

export class WorkflowEndpoints {
  client: SightglassClient;
  module: string;

  constructor(client: SightglassClient) {
    this.client = client;
    this.module = 'workflow';
  }

  async create(
    workflow: Partial<UserWorkflow>
  ): Promise<ApiResponse<WorkflowResponse>> {
    return this.client.postJson<WorkflowResponse>(
      `${this.module}/definition`,
      workflow
    );
  }

  async get(uuid: string): Promise<ApiResponse<WorkflowResponse>> {
    return this.client.getJson(`${this.module}/definition/${uuid}`);
  }

  async update(
    uuid: string,
    workflow: UserWorkflow
  ): Promise<ApiResponse<WorkflowResponse>> {
    return this.client.putJson<WorkflowResponse>(
      `${this.module}/definition/${uuid}`,
      workflow
    );
  }

  async delete(uuid: string): Promise<ApiResponse<string>> {
    return this.client.deleteJson<ApiResponse<string>>(
      `${this.module}/definition/${uuid}`
    );
  }

  async list(): Promise<ApiResponse<UserWorkflowSummary[]>> {
    return this.client.getJson(`${this.module}/definition`, {
      collectionWorkflow: 'true',
    });
  }

  async listAll(): Promise<ApiResponse<UserWorkflowSummary[]>> {
    return this.client.getJson(`${this.module}/definition`, {
      workflowType: 'User',
    });
  }

  async listRuns(
    params: GetWorkflowRunsQuery
  ): Promise<ApiResponse<UserWorkflowInstanceSummary[]>> {
    return this.client.getJson(`${this.module}/run`, params);
  }

  async listWorkflowRuns(
    workflowUuid: string,
    page: number
  ): Promise<ApiResponse<WorkflowInstancePage>> {
    const params = {page: page.toString()};
    return this.client.getJson(
      `${this.module}/definition/${workflowUuid}/runs`,
      params
    );
  }

  async getTasks(uuid: string): Promise<ApiResponse<WorkflowTaskResponse>> {
    return this.client.getJson(`${this.module}/run/${uuid}/tasks`);
  }

  async trigger(
    uuid: string,
    inputOverride?: DataNode
  ): Promise<ApiResponse<string>> {
    return this.client
      .postJson<string>(`${this.module}/run/${uuid}`, {
        inputOverride,
      })
      .catch((err: AxiosError) => {
        const errorTheme: Partial<ToastOptions> = {
          theme: 'colored',
          type: 'error',
        };

        if (err.response?.status === 429) {
          const errorMsg = (err.response.data as any).error ?? '';
          toast(
            `You've reached your usage limit for this month. ${errorMsg}`,
            errorTheme
          );
        } else {
          toast(
            `Unable to run workflow. ${err.response?.statusText ?? ''}`,
            errorTheme
          );
        }
        throw err;
      });
  }
}

export async function getWorkflowSuggestion(
  token: string,
  workflow_request: string,
  workflowUuid: string,
  abortController: AbortController,
  new_thread: boolean,
  callback: WorkflowSuggestionCallback
) {
  fetchEventSource(`${API_ENDPOINT}/workflow/suggest/`, {
    method: 'POST',
    headers: {
      Accept: 'text/event-stream',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      workflowUuid: workflowUuid,
      request: workflow_request,
      newChat: new_thread,
    }),
    signal: abortController.signal,
    onmessage: event => {
      console.debug('Event ', event);
      // Empty data message is used for keepalive
      if (event.data.length !== 0) {
        callback(JSON.parse(event.data));
      }
    },
  });
}

export async function deleteChatHistory(
  token: string,
  workflowUUID: string
): Promise<ApiResponse<void>> {
  return fetch(`${API_ENDPOINT}/workflow/chat/${workflowUUID}`, {
    method: 'DELETE',
    headers: create_headers(token),
  }).then(res => res.json());
}

export async function getClientHistory(
  token: string,
  workflowUUID: string
): Promise<ApiResponse<SystemChatMessage[]>> {
  return fetch(`${API_ENDPOINT}/workflow/chat/${workflowUUID}`, {
    method: 'GET',
    headers: create_headers(token),
  }).then(res => res.json());
}
